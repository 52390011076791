<template>
  <BittsPopover
    :show-menu="showMenu"
    placement="bottomRight"
    overlay-class="c-sharing-dashboard-filter__popover"
    @menu-visible="onMenuVisible"
  >
    <BittsTooltip placement="topRight">
      <BittsButton
        v-if="filter.name === OFFLINE_PARTNERS"
        :center-icon="['fas', 'ghost']"
        size="small"
        type="neutral"
        variant="outline"
        :active="filter.items[0].checked"
        @click.stop="showMenu = !showMenu"
      />
      <BittsButton
        v-else
        :text="btnName"
        :left-icon="filter.icon"
        :active="areFiltersActive"
        :disabled
        size="small"
        type="neutral"
        variant="outline"
        @click.stop="showMenu = !showMenu"
      >
        <template #extra-content>
          <div v-if="areFiltersActive">
            <BittsCount
              v-if="
                filter.name !== OVERRIDES || filter.name !== OFFLINE_PARTNERS
              "
              type="neutral"
              class="ml-4"
              :count="numFiltersActive"
            />
          </div>
        </template>
      </BittsButton>

      <template #title>
        {{ tooltipTitle }}
      </template>
    </BittsTooltip>
    <template #content>
      <BittsInput
        v-if="filter.name === POPULATIONS"
        v-model="popSearch"
        class="my-8 px-8"
        type="search"
        name="population_search"
        placeholder="Search for populations"
      />
      <div
        v-if="options.length"
        :class="`c-sharing-dashboard-filter__content--${filter.name}`"
        class="c-sharing-dashboard-filter__content"
      >
        <p class="c-sharing-dashboard-filter__header">
          {{ filter.headerName || '' }}
        </p>
        <BittsRadioGroupCards
          v-if="filter.name == OVERRIDES"
          :initial-value="selectedOverride"
          :options="filterOptions"
          @change="onChange"
        />
        <BittsSwitch
          v-else-if="filter.name === OFFLINE_PARTNERS"
          v-model="filterOptions[0].checked"
          :label="filterOptions[0].label"
          class="p-12"
          @change="onChange"
        />
        <div v-else class="flex flex-col">
          <div v-if="options.length">
            <div
              v-for="item in options"
              :key="item.name"
              class="c-sharing-dashboard-filter__filter-item"
              @click="onSelection(item)"
            >
              <BittsCheckbox :checked="item.checked" class="mr-8" />
              <div class="truncate">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="c-sharing-dashboard-filter__footer">
        <BittsButton
          type="neutral"
          text="Clear"
          size="small"
          class="w-full"
          @click="onClearFilters"
        />
        <BittsButton
          text="Apply"
          size="small"
          class="w-full"
          :disabled="applyBtnDisabled"
          @click="onApplyFilters"
        />
      </div>
    </template>
  </BittsPopover>
</template>
<script setup>
import {
  BittsButton,
  BittsCheckbox,
  BittsCount,
  BittsInput,
  BittsPopover,
  BittsRadioGroupCards,
  BittsSwitch,
  BittsTooltip,
} from '@crossbeam/bitts';

import { isEqual } from 'lodash';
import { computed, onMounted, ref } from 'vue';

import {
  OFFLINE_PARTNERS,
  OVERRIDES,
  POPULATIONS,
} from '@/constants/sharing_dashboard';

const props = defineProps({
  filter: {
    type: Object,
    default: () => {
      // do nothing
    },
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['filters-changed']);

const filterOptions = ref([]);

const btnName = computed(() => {
  if (props.filter.name !== OVERRIDES) {
    return props.filter.btnName;
  }
  return currentActiveOverride.value;
});

const showMenu = ref(false);

function onMenuVisible({ visible }) {
  showMenu.value = visible;
}

const selectedFilters = ref([]);

function onSelection(item) {
  item.checked = !item.checked;
  item.checked ? addItem(item) : removeItem(item);
}

function addItem(item) {
  selectedFilters.value.push(item.value);
}

function removeItem(item) {
  selectedFilters.value = selectedFilters.value.filter(
    (filterItem) => filterItem !== item.value,
  );
}

const selectedOverride = ref('all');

function onChange(val) {
  selectedOverride.value = val;
  filterOptions.value = filterOptions.value.map((opt) => ({
    ...opt,
    checked: opt.value === val && val !== 'all',
  }));
  if (
    val === 'all' ||
    (props.filter.name === OFFLINE_PARTNERS && val === false)
  ) {
    selectedFilters.value = [];
  } else {
    selectedFilters.value = [val];
  }
}

const activeFilters = computed(() =>
  props.filter.items.filter((item) => item.checked),
);
const numFiltersActive = computed(() => activeFilters.value.length);
const areFiltersActive = computed(() => !!numFiltersActive.value);
const currentActiveOverride = computed(() =>
  areFiltersActive.value
    ? activeFilters.value[0].btnName
    : props.filter.items[0].btnName,
);

function onApplyFilters() {
  emits('filters-changed', selectedFilters.value, props.filter.name);
  showMenu.value = false;
}

function onClearFilters() {
  selectedFilters.value = [];
  selectedOverride.value = 'all';
  filterOptions.value.forEach((item) => {
    item.checked = false;
  });
  emits('filters-changed', selectedFilters.value, props.filter.name);
  showMenu.value = false;
}

const applyBtnDisabled = computed(() =>
  isEqual(props.filter.items, filterOptions.value),
);

const popSearch = ref('');
const options = computed(() =>
  props.filter.name === POPULATIONS && popSearch.value.length
    ? filteredFilterOpts.value
    : filterOptions.value,
);
const filteredFilterOpts = computed(() =>
  filterOptions.value.filter((option) =>
    option.name
      .toLowerCase()
      .replace(/ /g, '')
      .includes(popSearch.value.toLowerCase().replace(/ /g, '')),
  ),
);

const tooltipTitle = computed(() => {
  switch (props.filter.name) {
    case POPULATIONS:
      return 'Filter down to your specific populations';
    case OVERRIDES:
      return 'Filter overrides on your populations';
    case OFFLINE_PARTNERS:
      return 'Include Offline Partners';
    default:
      return 'Filter for specific sharing settings on your populations';
  }
});
onMounted(() => {
  const copied = props.filter.items.map((filter) => Object.assign({}, filter));
  filterOptions.value = [...copied];
});
</script>
<style lang="pcss">
.c-sharing-dashboard-filter__popover {
  .ant-popover-content {
    .ant-popover-inner .ant-popover-inner-content {
      @apply min-w-[200px] max-w-[300px] min-h-[110px] max-h-[480px] overflow-y-scroll;
    }
    .c-sharing-dashboard-filter__content {
      @apply px-8 pb-8 mb-8;
      .bitts-radio-group-cards .ant-radio-wrapper {
        @apply border-0 m-0 py-4 px-8;
        &:hover {
          @apply rounded-4;
        }
      }
    }
    .c-sharing-dashboard-filter__content--overrides,
    .c-sharing-dashboard-filter__content--populations,
    .c-sharing-dashboard-filter__content--sharing_setting {
      @apply min-h-[180px];
    }
    .c-sharing-dashboard-filter__footer {
      @apply flex p-8 gap-8 w-full bg-white border-solid
      border border-t rounded-t-none rounded border-neutral-border absolute bottom-0 right-0;
    }
  }
}

.c-sharing-dashboard-filter__header {
  @apply text-sm text-neutral-text self-center py-4;
}
.c-sharing-dashboard-filter__option {
  @apply text-neutral-text-strong;
}
.c-sharing-dashboard-filter__filter-item {
  @apply flex items-center py-4 px-8 rounded-4 cursor-pointer mb-4 last:mb-0 hover:bg-neutral-background select-none;
}
</style>
