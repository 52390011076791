<template>
  <BittsPopover
    :mount-to-body="true"
    trigger="click"
    placement="right"
    class="report-overflow-menu"
  >
    <FontAwesomeIcon
      :icon="['fas', 'ellipsis-h']"
      :style="{ height: '12px', width: '12px' }"
      class="report-overflow-menu__button"
    />
    <template #content>
      <div class="flex flex-col text-brand-navy text-sm my-4">
        <RouterLink
          v-if="!errors.length"
          :to="duplicateReportRoute"
          class="report-overflow-menu__overflow-item"
        >
          Duplicate Report
        </RouterLink>
        <button
          v-if="!hideExport && !errors.length"
          class="report-overflow-menu__overflow-item"
          @click="onExport"
          type="button"
        >
          Export
        </button>
        <RouterLink
          :to="deleteReportRoute"
          class="report-overflow-menu__overflow-item"
        >
          Delete Report
        </RouterLink>
      </div>
    </template>
  </BittsPopover>
</template>

<script>
import { BittsPopover } from '@crossbeam/bitts';

import { DateTime } from 'luxon';

import useExport from '@/composables/useExport';
import { EVENT_SITES } from '@/constants/analytics';
import {
  ECOSYSTEM,
  EXPORT_IMPACT_TO_NAME_LOOKUP,
  GREENFIELD_TYPE,
} from '@/constants/reports';
import { CHILD_ROUTES } from '@/constants/routes';
import urls from '@/urls';

export default {
  name: 'ReportOverflowMenu',
  components: { BittsPopover },
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
    hideExport: {
      type: Boolean,
      default: false,
    },
    isOnReportsPage: {
      type: Boolean,
      default: true,
    },
    report: {
      type: Object,
      default: () => {
        // do nothing
      },
    },
    reportType: {
      type: String,
      default: '',
    },
    partnerOrgIds: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { performExport } = useExport();

    return { performExport };
  },
  computed: {
    analyticsPayload() {
      const payload = {
        population_id: this.exportQueryParams.our_population_ids,
        partner_id: this.partnerOrgIds,
        location: 'ellipsis_out_report',
        report_id: this.report.id,
        report_name: this.report.name,
        report_type: this.reportType,
        partner_impact_scores: this.report.partner_impact_scores || [],
        event_site: EVENT_SITES.REPORT_OVERFLOW_MENU,
      };
      if (this.reportType === GREENFIELD_TYPE) {
        payload.greenfield_type = this.report.consolidated_report_type;
      }
      return payload;
    },
    deleteReportRoute() {
      if (this.isOnReportsPage) {
        return {
          name: CHILD_ROUTES.reports.REPORTS_DELETE,
          params: { id: this.report.id },
          query: { id: this.report.id },
        };
      }
      return {
        name: CHILD_ROUTES.folder.REPORTS_DELETE,
        params: { folder_id: this.report.folder_uuid },
        query: { id: this.report.id },
      };
    },
    duplicateReportRoute() {
      if (this.isOnReportsPage) {
        return {
          name: CHILD_ROUTES.reports.REPORTS_DUPLICATE,
          params: { id: this.report.id },
          query: { id: this.report.id },
        };
      }
      return {
        name: CHILD_ROUTES.folder.REPORTS_DUPLICATE,
        params: { folder_id: this.report.folder_uuid },
        query: { id: this.report.id },
      };
    },
    exportQueryParams() {
      let exportName = this.report.name;
      if (this.report.partner_impact_scores?.length) {
        // we can only do one at at a time, for now
        const impactFilter = this.report.partner_impact_scores[0];
        exportName += ` ${EXPORT_IMPACT_TO_NAME_LOOKUP[impactFilter]}`;
      }
      const params = {
        consolidated_report_type: this.report.consolidated_report_type,
        our_population_ids: this.report.our_population_ids,
        partner_population_ids: this.report.partner_population_ids,
        columns: this.report.columns.map((col) => {
          if (!col.source_field_id) {
            const newCol = {};
            newCol.column_order = col.column_order;
            newCol.column_type = col.column_type;
            return newCol;
          }
          return col;
        }),
        filters: this.report.filters.map(this.transformFilter),
        partner_standard_populations:
          this.report.partner_standard_populations || [],
        exported_at: DateTime.local().toFormat('f'),
        export_name: exportName,
        report_id: this.report.id,
      };
      const isAllPartnersType =
        this.report.consolidated_report_type === ECOSYSTEM;
      if (isAllPartnersType) params.partner_population_ids = [];
      return params;
    },
    exportUrl() {
      return urls.reportData.export;
    },
  },
  methods: {
    async onExport() {
      const params = this.exportQueryParams;
      await this.performExport(params, this.exportUrl);
      this.$iteratively.userExportedReport(this.analyticsPayload);
    },
    transformFilter(filter) {
      const newFilter = { ...filter };
      delete newFilter.report_id;
      delete newFilter.id;
      delete newFilter.tableName;
      return newFilter;
    },
  },
};
</script>

<style lang="pcss">
.report-overflow-menu {
  @apply flex items-center justify-end h-full mb-4;
}
.report-overflow-menu__button {
  @apply text-neutral-400 p-4 rounded-bts-sm hover:bg-primary-background-medium
  hover:bg-blend-overlay hover:bg-opacity-[.08];
}
.report-overflow-menu__overflow-item {
  @apply cursor-pointer px-16 py-4 text-left;
}

.report-overflow-menu__overflow-item:hover {
  @apply bg-primary-background-weak;
}
</style>
