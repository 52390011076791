<template>
  <div v-if="canEditPopulation" class="sidebar__footer">
    <div v-if="activeTab === 'overview'">
      <div v-if="isEditMode" class="sidebar__footer-inner">
        <BittsButton
          v-if="!isUserEditing"
          type="neutral"
          variant="outline"
          text="Cancel"
          class="w-full"
          @click="handleCancelPopulationCreation"
        />
        <BittsButton
          :disabled="!isPopulationValid || processingWarning.isError"
          :loading="loading"
          text="Continue"
          class="w-full"
          @click="$emit('continue')"
        />
      </div>
      <div v-else class="sidebar__footer-inner">
        <BittsButton
          :loading="loading"
          text="Edit"
          type="neutral"
          variant="outline"
          @click="$emit('edit')"
        />
        <BittsButton
          :loading="loading"
          text="Save Population"
          :disabled="!populationHasChanged"
          class="mx-8 w-full"
          @click="$emit('save-and-run')"
        />
        <BittsButton
          :disabled="!population.id"
          type="danger"
          variant="ghost"
          class="px-12"
          :center-icon="['fas', 'trash-can']"
          @click="handlePopulationDeletion"
        />
      </div>
    </div>
    <div v-else>
      <div class="sidebar__footer-inner">
        <BittsButton
          :loading="loading"
          :disabled="!isPopulationValid"
          type="neutral"
          variant="outline"
          text="Preview"
          class="w-1/2"
          @click="$emit('run')"
        />
        <BittsButton
          :loading="loading"
          :disabled="
            (isEditMode || isUserEditing) &&
            (!populationHasChanged || !isPopulationValid)
          "
          text="Save Population"
          class="w-1/2"
          @click="$emit('save-and-run')"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { BittsButton } from '@crossbeam/bitts';

import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  activeTab: {
    type: String,
    default: 'overview',
  },
  population: {
    type: Object,
    default: null,
  },
  populationHasChanged: {
    type: Boolean,
    default: false,
  },
  canEditPopulation: {
    type: Boolean,
    default: false,
  },
  isEditMode: {
    type: Boolean,
    default: false,
  },
  isUserEditing: {
    type: Boolean,
    default: false,
  },
  isPopulationValid: {
    type: Boolean,
    default: true,
  },
  processingWarning: {
    type: Object,
    default: () => ({}),
  },
});
defineEmits(['edit', 'continue', 'run', 'save-and-run']);
const router = useRouter();
const route = useRoute();

const offlinePartnerUuid = computed(() => route.query?.offlinePartnerUuid);
const offlinePartnerId = computed(() => route.query?.offlinePartnerId);
async function handleCancelPopulationCreation() {
  if (offlinePartnerUuid.value) {
    await router.push({
      name: 'partner_details',
      params: { partner_org_id: offlinePartnerId.value },
      query: {
        tab: 'data',
      },
    });
    return;
  }
  await router.push({ name: 'populations' });
}

async function handlePopulationDeletion() {
  if (offlinePartnerUuid.value) {
    await router.push({
      name: 'offline-delete-population-modal',
      query: {
        offlinePartnerId: offlinePartnerId.value,
        offlinePartnerUuid: offlinePartnerUuid.value,
      },
    });
    return;
  }
  await router.push({ name: 'delete-population-modal' });
}
</script>

<style lang="pcss" scoped>
.sidebar__footer {
  @apply p-16 w-full border-neutral-border border-t;
}

.sidebar__footer-inner {
  @apply flex items-center justify-between gap-8;
}
</style>
