<template>
  <BittsCallout
    data-testid="downgrade-callout"
    :title="title"
    :subtitle="subtitle"
    :icon="['fad', 'arrow-down-from-arc']"
    size="small"
    type="warning"
  >
    <template #right-item>
      <slot name="right-item">
        <BittsButton
          v-if="hasBillingPermissions || actionIsTeam"
          size="x-small"
          :text="buttonText"
          class="ml-auto"
          @click="handleAction"
        />
      </slot>
    </template>
  </BittsCallout>
</template>
<script setup lang="ts">
import { BittsButton, BittsCallout } from '@crossbeam/bitts';

import { computed } from 'vue';
import { useRouter } from 'vue-router';

import useAuth from '@/composables/useAuth';

const props = withDefaults(defineProps<Props>(), {
  buttonText: 'Restore plan',
});

const router = useRouter();
const { hasScope } = useAuth();

type ButtonText = 'Restore plan' | 'View team';
type Props = {
  title: string;
  subtitle: string;
  buttonText?: ButtonText;
};

const hasBillingPermissions = computed(() => hasScope('write:billing'));

const actionIsTeam = props.buttonText === 'View team';
async function handleAction() {
  if (actionIsTeam) await router.push({ name: 'team' });
  else await router.push({ name: 'restore-plan' });
}
</script>
