import axios from 'axios';
import { defineStore } from 'pinia';
import { computed } from 'vue';

import {
  ATTRIBUTION_FILTER_QUERY_PARAMS,
  ATTRIBUTION_METRIC_TYPES,
} from '@/constants/attribution';
import { ATTRIBUTION_ACCESS_OVERRIDE } from '@/constants/feature_flags';
import { BASE_ROUTES } from '@/constants/routes';
import { captureException } from '@/errors';
import { ls } from '@/local_storage';
import urls from '@/urls';

import MetricsModule from './Attribution/metrics';
import Opportunities from './Attribution/opportunities';
import { useAttributionState } from './Attribution/state';
import { buildCurrentQuarterDateRangeQuery } from './Attribution/utils';
import { useBillingStore } from './BillingStore';
import { useFeatureFlagStore } from './FeatureFlagsStore';
import { useFlashesStore } from './FlashesStore';
import { useRootStore } from './RootStore';
import { useSourcesStore } from './SourcesStore';

const { ORDER, SORT, IS_CLOSED, IS_WON } = ATTRIBUTION_FILTER_QUERY_PARAMS;

export const useAttributionStore = defineStore('AttributionStore', () => {
  const {
    $reset,
    accountActivities,
    isCached,
    opportunities,
    pagination,
    recentCustomerMovements,
    rollupMetrics,
    unattributedOpportunities,
    eiAttributionMetrics,
  } = useAttributionState();

  const featureFlagStore = useFeatureFlagStore();
  const sourcesStore = useSourcesStore();
  const rootStore = useRootStore();

  const hasAttributionAccess = computed(() => {
    const { isEnterpriseTier } = useBillingStore();

    return (
      isEnterpriseTier ||
      featureFlagStore.hasFeatureFlag(ATTRIBUTION_ACCESS_OVERRIDE)
    );
  });
  const {
    TOTAL_INFLUENCED,
    TOTAL_SOURCED,
    TOTAL_ATTRIBUTED,
    TOTAL_UNATTRIBUTED,
    TOTAL_ATTRIBUTED_COUNT,
    TOTAL_UNATTRIBUTED_COUNT,
  } = ATTRIBUTION_METRIC_TYPES;

  function initAttributionStore(route = {}, { useCache = false } = {}) {
    if (!route.name) {
      throw new Error('route is required to initialize AttributionStore');
    }
    if (!useCache) {
      isCached.value = true;
      const baseRoute = route.matched?.at(1)?.name;

      const { query = {} } = route;
      const { sort, order } = query;

      if (!sort || !order) {
        query[SORT] = 'close_date';
        query[ORDER] = 'desc';
      }

      switch (baseRoute) {
        case BASE_ROUTES.ATTRIBUTION:
          return refreshAttributionStore(query, {
            refreshUnattributed: true,
          });
        case BASE_ROUTES.ECOSYSTEM_INSIGHTS:
          return refreshEcosystemDashboard(query);
      }
    }
  }

  async function refreshAttributionStore(
    query,
    { refreshUnattributed = false, refreshMetrics = true } = {},
  ) {
    const settingsFromLS = ls.attributionSettings.get();
    try {
      if (hasAttributionAccess.value) {
        const promises = [Opportunities.refreshOpportunities(query)];

        if (refreshMetrics) {
          promises.push(MetricsModule.refreshMetrics(query, settingsFromLS));
        }
        if (refreshUnattributed) {
          promises.push(Opportunities.fetchUnattributedOpportunities());
        }
        await Promise.all(promises);
      }
    } catch (err) {
      isCached.value = false;
      captureException(err);
    }
  }

  async function refreshEcosystemDashboard(
    query,
    { refreshMetrics = true } = {},
  ) {
    // add quarter date range to both metrics and oppies
    const quarterRangeQuery = buildCurrentQuarterDateRangeQuery(
      rootStore.quarterLookup,
    );

    const queryWithQuarterRange = {
      ...query,
      ...quarterRangeQuery,
    };

    try {
      if (hasAttributionAccess.value) {
        const promises = [
          Opportunities.refreshClosedWonOppies(queryWithQuarterRange),
        ];

        if (refreshMetrics) {
          promises.push(
            MetricsModule.fetchEiAttributionMetrics({
              ...queryWithQuarterRange,
              [TOTAL_UNATTRIBUTED]: true,
              [TOTAL_SOURCED]: true,
              [TOTAL_INFLUENCED]: true,
              [TOTAL_ATTRIBUTED]: true,
              [TOTAL_UNATTRIBUTED_COUNT]: true,
              [TOTAL_ATTRIBUTED_COUNT]: true,
              [IS_CLOSED]: true,
              [IS_WON]: true,
            }),
          );
        }

        await Promise.all(promises);
      }
    } catch (err) {
      isCached.value = false;
      captureException(err);
    }
  }

  async function fetchTimelineEvents(deal) {
    await sourcesStore.readySync;
    const { record_id: recordId, feed_id: feedId } = deal;

    const accountSource = sourcesStore
      .getSourcesByMdmType('account')
      .filter((source) => source.feed_id === feedId)
      .at(0);

    const url = urls.activityTimeline.all(accountSource.id, recordId);
    const { data } = await axios.get(url, {
      params: {
        limit: 1000,
      },
    });

    accountActivities.value = data.items;
  }

  async function fetchRecentCustomerMovements() {
    const url = urls.activityTimeline.recentCustomers;
    const { data } = await axios.get(url);
    recentCustomerMovements.value =
      data?.items.filter(
        (activity) => activity.account_name && activity.account_website,
      ) ?? [];
  }

  async function saveAttributions({ payload, masterUUID, opportunityID }) {
    const url = urls.attribution.put(masterUUID, opportunityID);
    const promises = [
      axios.put(url, payload),
      dismissRecommendedAttribution({ masterUUID, opportunityID }, false),
    ];
    await Promise.all(promises);
  }

  async function dismissRecommendedAttribution(
    { masterUUID, opportunityID },
    showToast = true,
  ) {
    const flashesStore = useFlashesStore();
    try {
      const url = urls.attribution.dismiss(masterUUID, opportunityID);
      await axios.put(url, {
        is_dismissed: true,
      });
      const oppInState =
        Opportunities.getOpportunityInCurrentStateByID(opportunityID);
      if (oppInState) oppInState.is_dismissed = true;
      unattributedOpportunities.value = unattributedOpportunities.value.filter(
        (oppy) => {
          return (
            oppy.record_master_uuid !== masterUUID && oppy.id !== opportunityID
          );
        },
      );
      if (showToast) {
        flashesStore.addSuccessFlash({
          message: 'Attribution dismissed',
        });
      }
    } catch (err) {
      flashesStore.addErrorFlash({
        message: 'Something went wrong',
        description: 'Attribution was not dismissed',
      });
      throw err;
    }
  }

  return {
    $reset,
    initAttributionStore,
    refreshAttributionStore,
    refreshEcosystemDashboard,
    saveAttributions,
    fetchRecentCustomerMovements,
    fetchTimelineEvents,
    dismissRecommendedAttribution,

    // OPPORTUNITIES
    fetchOpportunity: Opportunities.fetchOpportunity,
    fetchOpportunityOverlaps: Opportunities.fetchOpportunityOverlaps,

    // METRICS
    getUpdatedMetrics: MetricsModule.getUpdatedMetrics,
    refreshMetrics: MetricsModule.refreshMetrics,

    // STATE
    isCached,
    opportunities,
    pagination,
    unattributedOpportunities,
    accountActivities,
    recentCustomerMovements,
    rollupMetrics,
    hasAttributionAccess,
    eiAttributionMetrics,
  };
});
