<template>
  <BittsModal
    class="c-invite-team-member"
    :save-text="`Send invite${approvingSingleInviteRequest ? '' : 's'}`"
    :loading="loading || !ready"
    :visible="true"
    :saving="isSendingInvites"
    :width="600"
    :disabled="sendInvitesDisabled"
    @closed="handleModalClosed"
    @saved="handleInviteSubmitted"
  >
    <template #content>
      <h1 class="title">
        Invite teammate{{ approvingSingleInviteRequest ? '' : 's' }} to
        Crossbeam
      </h1>
      <SelectSeatType
        v-if="!approvingSeatRequest"
        :auths="emails"
        :picked-seat="pickedSeat"
        @seat-selected="handleChangeSeat"
      />
      <SSOToggle v-model="isSSO" />
      <UserRequestCard v-if="approvingSingleInviteRequest" />
      <AddEmails
        v-else
        data-testid="add-emails"
        class="add-emails"
        :disabled="approvingSeatRequest"
        :invite-request-id="inviteRequestId"
        @emails="handleAddEmail"
      />
      <div class="flex flex-col gap-16 w-full mt-24">
        <OutOfSeatsCTA
          :seat-type="pickedSeat.value"
          :user-count="emails.length"
        />
        <SelectCoreRole v-model="pickedCoreRole" :picked-seat="pickedSeat" />
        <SelectSalesRole
          v-if="!isFreeTier"
          v-model="pickedSalesRole"
          :picked-seat="pickedSeat"
        />
      </div>
      <DowngradeCallout
        v-if="isSubscriptionCancelled"
        class="mt-16"
        :title="`This user will be removed on ${subscriptionRenewalDate}`"
        subtitle="You've downgraded and are losing all Sales seats"
      />
    </template>
  </BittsModal>
</template>

<script setup>
import { BittsModal } from '@crossbeam/bitts';
import { useScreenSize } from '@crossbeam/pointbreak';

import { useHead } from '@unhead/vue';
import { pluralize } from 'humanize-plus';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import DowngradeCallout from '@/components/billing/DowngradeCallout.vue';
import AddEmails from '@/components/team/AddEmails.vue';
import OutOfSeatsCTA from '@/components/team/OutOfSeatsCTA.vue';
import UserRequestCard from '@/components/team/seat-requests/UserRequestCard.vue';
import SelectCoreRole from '@/components/team/SelectCoreRole.vue';
import SelectSalesRole from '@/components/team/SelectSalesRole.vue';
import SelectSeatType from '@/components/team/SelectSeatType.vue';
import SSOToggle from '@/components/team/SSOToggle.vue';

import useAuth from '@/composables/useAuth';
import useHasFeature from '@/composables/useHasFeature';
import useIteratively from '@/composables/useIteratively';
import useSeats from '@/composables/useSeats';
import { EVENT_SITES } from '@/constants/analytics';
import { SSO_ONLY } from '@/constants/me';
import { SALES, V4_SEAT_OPTIONS } from '@/constants/team_v4';
import { captureException } from '@/errors';
import {
  allReady,
  useBillingStore,
  useFlashesStore,
  useRolesStore,
  useSeatRequestsStore,
  useTeamStore,
} from '@/stores';

defineProps({
  inviteRequestId: {
    type: String,
    default: null,
  },
});

useHead({ title: 'Invite - Team - Crossbeam' });

const loading = ref(false);
const router = useRouter();

const seatRequestsStore = useSeatRequestsStore();
const flashesStore = useFlashesStore();
const rolesStore = useRolesStore();
const teamStore = useTeamStore();
const billingStore = useBillingStore();

const { isFreeTier, isSubscriptionCancelled, subscriptionRenewalDate } =
  storeToRefs(billingStore);

const { iteratively } = useIteratively();
const { currentOrg } = useAuth();
const { seatsRemainingMap } = useSeats();
const { canGoOverSeatQuota } = useHasFeature();
const { isMobile } = useScreenSize();

const route = useRoute();

const ready = allReady(teamStore, rolesStore, seatRequestsStore);

/* Picking a seat type (core/sales) */
const options = JSON.parse(JSON.stringify(V4_SEAT_OPTIONS));
const querySeatType = route.query.seat_type;
const initialSeatType = options.find(({ value }) => {
  if (querySeatType) return value === querySeatType;
  return seatsRemainingMap.value[value] > 0;
});
const pickedSeat = ref(initialSeatType || {});
function handleChangeSeat(newSeat) {
  pickedSeat.value = newSeat;
  if (newSeat.value === SALES) pickedCoreRole.value = null;
  router.replace({
    name: route.name,
    query: { ...route.query, seat_type: pickedSeat.value.value },
  });
}

/* Emails */
const seatRequest = computed(() =>
  seatRequestsStore.getSeatRequestByEmail(emails.value.at(0)),
);
const emails = ref([]);
async function handleAddEmail(newEmails) {
  emails.value = newEmails;
  if (approvingSeatRequest.value && !seatRequest.value) {
    // If we cannot find the seat request, then close the modal
    flashesStore.addSuccessFlash({
      message: 'Request no longer exists',
      description: 'Another admin responded to this request already',
    });
    await handleModalClosed();
    return;
  }
  router.replace({
    name: route.name,
    query: { ...route.query, emails: newEmails.join(',') },
  });
}

/* Roles */
const pickedCoreRole = ref(null);
const pickedSalesRole = ref(null);

/* SSO */
const ssoRequired = computed(() => currentOrg.value.login_method === SSO_ONLY);
const isSSO = ref(ssoRequired.value);

/* Submitting invites */
const isSendingInvites = ref(false);
const sendInvitesDisabled = computed(() => {
  return (
    emails.value.length === 0 ||
    overSeatLimit.value ||
    (!isFreeTier.value && pickedSalesRole.value === null) ||
    (!pickedSalesRole.value && !pickedCoreRole.value)
  );
});
const seatLimit = computed(
  () => seatsRemainingMap.value[pickedSeat.value.value],
);
const overSeatLimit = computed(() => {
  if (canGoOverSeatQuota.value) return false;
  return emails.value.length > seatLimit.value;
});

const handleInviteSubmitted = async () => {
  isSendingInvites.value = true;
  try {
    const invites = await teamStore.sendInvites({
      emails: emails.value,
      crossbeamRoleId: pickedCoreRole.value,
      salesEdgeRoleId: pickedSalesRole.value,
      isSSO: isSSO.value,
    });

    flashesStore.addSuccessFlash({
      message: `${pickedCoreRole.value ? 'Full Access' : 'Sales'} ${pluralize(emails.value.length, 'invitation')} sent`,
    });

    const promises = [teamStore.refreshTeamStore()];
    if (approvingSeatRequest.value)
      promises.push(seatRequestsStore.refreshSeatRequestsStore());
    await Promise.all(promises);

    teamStore.refreshTeamStore();

    invites.forEach(({ email, role_id: roleId }) => {
      const iterativelyPayload = {
        teammate_email: email,
        seat_type: pickedSeat.value.value,
        invite_location: route.name,
        event_site: EVENT_SITES.TEAM_INVITE_MODAL,
      };
      if (roleId) iterativelyPayload.role = rolesStore.getRoleById(roleId).name;
      iteratively.userInvitesTeammate(iterativelyPayload);
    });
  } catch (err) {
    captureException(err);
    if (err?.response.status === 402) {
      flashesStore.addErrorFlash({ message: 'Invitation exceeds user quota' });
    } else {
      flashesStore.addErrorFlash({
        message: `Something went wrong with sending the ${pluralize(emails.value.length, 'invite')}`,
      });
    }
  } finally {
    isSendingInvites.value = false;
    billingStore.refreshBillingStore(false);
    handleModalClosed();
  }
};

/* Approving access request */
const approvingSeatRequest = computed(
  () => route.name === 'approve_seat_request',
);
const approvingSingleInviteRequest = computed(
  () => approvingSeatRequest.value && emails.value?.length === 1,
);

/* Close modal */
async function handleModalClosed() {
  if (route.query?.cancelDestination)
    await router.push({ name: route.query.cancelDestination });
  else await router.push({ name: isMobile.value ? 'home' : 'team' });
}
</script>
<style lang="pcss" scoped>
.title {
  @apply text-xl text-neutral-text-strong font-bold mt-[-36px] text-center mb-24;
}
.core-callout {
  @apply bg-upsell-background-weak rounded-16;
}

.icon-background {
  @apply w-32 h-32 rounded-full bg-neutral-background-weak flex items-center justify-center ml-8;
}
</style>
<style lang="pcss">
.c-invite-team-member {
  .ant-modal-body {
    @apply rounded-[24px] bg-top-gradient from-neutral-accent/20 to-neutral-accent/0;
  }

  .c-bitts-modal__buttons--wrapper {
    @apply flex w-full items-center justify-between;
  }

  .add-emails {
    .bitts-form-label__text {
      @apply opacity-100 !important;
    }
  }
}
</style>
