<template>
  <div class="c-synctree" @scroll.passive="logScroll">
    <div class="c-synctree__header" :class="{ scrolled: scrollFromTop > 0 }">
      <div class="flex items-center justify-between">
        <h2 class="text-neutral-text-strong font-bold"> All Data </h2>
        <span class="flex items-center gap-4">
          <FontAwesomeIcon
            v-if="allCheckedFieldIds.length > fieldLimit"
            :icon="['fas', 'exclamation-triangle']"
            :style="{ height: '16px', width: '16px' }"
            class="text-warning-accent"
          />
          <span
            data-testid="synctree-limit-check"
            :class="[
              allCheckedFieldIds.length > fieldLimit
                ? 'text-warning-text'
                : 'text-neutral-text',
            ]"
          >
            {{ allCheckedFieldIds.length }}<span class="mx-2">/</span
            >{{ fieldLimit }} fields selected
          </span>
        </span>
      </div>
      <BittsInput
        type="search"
        :model-value="searchValue"
        placeholder="Search"
        name="synctree-search"
        class="relative max-w-[400px] mt-8"
        size="small"
        data-testid="c-synctree__search__input"
        @update:model-value="search"
      />
    </div>
    <div class="c-synctree__branches">
      <SyncTreeBranch
        v-for="object in objects"
        :key="object"
        :search="delayedSearchValue"
        :object-type="object"
        :source-field-map="sourceFieldMap"
        :lookup-field-ids="lookupFieldIds"
        :checked-lookup-ids="checkedLookupIds"
        :checked-reference-ids="checkedReferenceIds"
        :reference-field-ids="referenceFieldIds"
        @update="handleUpdatedField"
        @bulk-update="handleBulkUpdateFields"
      />
    </div>
  </div>
</template>
<script setup>
import { BittsInput } from '@crossbeam/bitts';

import { ref } from 'vue';

import SyncTreeBranch from '@/components/data-sources/data-templates/SyncTreeBranch.vue';

import useDebouncedSearch from '@/composables/useDebouncedSearch';

defineProps({
  sourceFieldMap: {
    type: Object,
    required: true,
  },
  lookupFieldIds: {
    type: Array,
    default: () => [],
  },
  checkedReferenceIds: {
    type: Array,
    default: () => [],
  },
  referenceFieldIds: {
    type: Array,
    default: () => [],
  },
  objects: {
    type: Array,
    default: () => [],
  },
  checkedLookupIds: {
    type: Array,
    default: () => [],
  },
  allCheckedFieldIds: {
    type: Array,
    default: () => [],
  },
  fieldLimit: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['update', 'bulk-update']);

const { searchValue, delayedSearchValue, search } = useDebouncedSearch({
  limit: 250,
});

function handleUpdatedField({ sourceField }) {
  emit('update', { sourceField });
}

function handleBulkUpdateFields({ sourceFields }) {
  emit('bulk-update', { sourceFields });
}

/* Used to hide/show box shadow below header content */
const scrollFromTop = ref(0);
function logScroll(e) {
  scrollFromTop.value = e.target.scrollTop;
}
</script>
<style lang="pcss" scoped>
.c-synctree {
  @apply pb-24;
  max-height: 70vh;
  overflow: auto;
}

.c-synctree__header {
  @apply sticky top-0 bg-white pb-24 z-10 pt-24 px-24;
  &.scrolled {
    @apply border-b border-neutral-border shadow-overlay;
  }
}

.c-synctree__branches {
  @apply px-24;
}

.c-synctree__header.scrolled {
  @apply shadow-component;
}
</style>
