<template>
  <div class="partner-population-overrides">
    <div class="partner-population-overrides__enable">
      <div class="border-b border-solid border-transparent">
        Enable per Population
      </div>
      <BittsTooltip
        :include-cta="true"
        learn-more-link="https://help.crossbeam.com/en/articles/4236661-per-population-overrides"
        placement="right"
        class="partner-population-overrides__tooltip"
      >
        overrides
        <template #title>
          You can use per Population overrides to selectively share data with a
          partner's specific Population
        </template>
      </BittsTooltip>
      <BittsSwitch
        v-model="enabled"
        :disabled="!canEditSharing"
        class="ml-auto"
        @change="(checked) => emit('pop-to-pop-checked', checked)"
      />
    </div>
    <div
      v-if="enabled"
      data-testid="population-overrides-enabled"
      class="text-neutral-text-weak"
    >
      Overrides
    </div>
    <div v-if="enabled" class="flex items-stretch gap-16">
      <div class="flex flex-col gap-8 w-1/2">
        <button
          v-for="popId in partnerPopOverrideIds"
          :key="`override__${popToPopSettings[popId].partnerPopulation.id}`"
          :class="{
            'partner-population-overrides__selected-override':
              selectedOverride?.partnerPopulation.id ===
              popToPopSettings[popId].partnerPopulation.id,
          }"
          class="partner-population-overrides__override"
          @click="selectedOverride = popToPopSettings[popId]"
          type="button"
        >
          <div class="flex flex-col items-start">
            {{ popToPopSettings[popId].partnerPopulation.name }}
            <div class="font-normal text-neutral-text text-left">
              {{
                `${popToPopSettings[popId].selectedSourceFieldIds.length} fields`
              }}
            </div>
          </div>
          <BittsButton
            v-if="canEditSharing"
            data-testid="delete-override-button"
            variant="ghost"
            size="x-small"
            :center-icon="['fas', 'trash-can']"
            class="text-neutral-text-weak"
            @click.stop="onRemoveOverride(popId)"
          />
        </button>
        <div
          v-if="partnerPopsWithNoOverride.length && canEditSharing"
          class="partner-population-overrides__add-override"
        >
          Add an override
          <BittsSelect
            v-model="overrideSelectorPlaceholder"
            data-testid="new-override-select"
            placeholder="Select a population"
            :options="partnerPopsWithNoOverride"
            @change="onOverrideAdded"
          />
        </div>
      </div>
      <div class="partner-population-overrides__field-selection">
        <div v-if="selectedOverride" class="font-bold">
          {{
            `When overlapping ${selectedOverride?.partnerPopulation.name} share:`
          }}
          <DataSharingFieldSelector
            data-testid="override-selection-tree"
            class="mt-16"
            :can-edit-sharing="canEditSharing"
            :show-greenfield-tag="false"
            :selected-fields="selectedFields"
            @fields-selected="onFieldsSelected"
          />
        </div>
        <BittsEmptyState
          v-else
          title="Add a Population"
          class="partner-population-overrides__empty-state"
          font-awesome-icon-class="partner-population-overrides__empty-state-icon"
          :font-awesome-icon="['far', 'chart-pie-simple']"
          :font-awesome-icon-style="{ height: '40px', width: '40px' }"
        >
          <template #subtitle>
            <div class="text-neutral-text">
              You will be able to select specific fields from the selected
              Population
            </div>
          </template>
        </BittsEmptyState>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  BittsButton,
  BittsEmptyState,
  BittsSelect,
  BittsSwitch,
  BittsTooltip,
} from '@crossbeam/bitts';

import { computed, inject, ref } from 'vue';

import DataSharingFieldSelector from '@/components/partners/DataSharingSettings/DataSharingFieldSelector.vue';

import { DATA_SHARING_STATE_KEY } from '@/constants/data_shares';

const props = defineProps({
  canEditSharing: {
    type: Boolean,
    default: true,
  },
  partnerPops: {
    type: Array,
    default: () => [],
  },
  popToPopOverridesEnabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  'override-added',
  'override-deleted',
  'pop-to-pop-checked',
]);

const enabled = ref(props.popToPopOverridesEnabled);

const { popToPopSettings } = inject(DATA_SHARING_STATE_KEY);

const partnerPopOverrideIds = computed(() => {
  return Object.keys(popToPopSettings.value);
});
const partnerPopsWithNoOverride = computed(() => {
  return props.partnerPops.reduce((pops, partnerPop) => {
    if (partnerPopOverrideIds.value.includes(partnerPop.id.toString())) {
      return pops;
    }
    const partnerPopForOptions = {
      ...partnerPop,
      label: partnerPop.name,
      value: partnerPop.id,
    };
    return [...pops, partnerPopForOptions];
  }, []);
});

function getInitialSelectedOverride() {
  if (!partnerPopOverrideIds.value.length) return null;
  return popToPopSettings.value[partnerPopOverrideIds.value[0]];
}
const selectedOverride = ref(getInitialSelectedOverride());
const selectedFields = computed(
  () => selectedOverride.value?.selectedSourceFieldIds || [],
);

function onFieldsSelected(fields) {
  const overrideId = selectedOverride.value.partnerPopulation.id;
  popToPopSettings.value[overrideId].selectedSourceFieldIds = fields;
}

const overrideSelectorPlaceholder = ref(null);
function onOverrideAdded(popId) {
  emit('override-added', parseInt(popId));
  const pop = partnerPopsWithNoOverride.value.find(
    (population) => population.id === popId,
  );
  popToPopSettings.value[popId] = {
    partnerPopulation: pop,
    selectedSourceFieldIds: [],
  };
  selectedOverride.value = popToPopSettings.value[popId];
  overrideSelectorPlaceholder.value = null;
}
function onRemoveOverride(popId) {
  const shareExistsToRemove = !!popToPopSettings.value[popId].dataShare?.id;
  if (shareExistsToRemove) emit('override-deleted', parseInt(popId));
  // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
  delete popToPopSettings.value[popId];
  if (selectedOverride.value?.partnerPopulation.id === parseInt(popId)) {
    if (!partnerPopOverrideIds.value.length) selectedOverride.value = null;
    else {
      const partnerPopId = parseInt(partnerPopOverrideIds.value[0]);
      selectedOverride.value = popToPopSettings.value[partnerPopId];
    }
  }
}
</script>

<style lang="pcss" scoped>
.partner-population-overrides {
  @apply border border-solid border-neutral-border p-12 rounded-bts-md
    flex flex-col gap-8;
}
.partner-population-overrides__add-override {
  @apply font-bold mt-16 text-sm flex flex-col gap-4;
}
.partner-population-overrides__empty-state {
  @apply h-full;
  :deep(.c-bitts-empty-state-large) {
    @apply py-24 mt-0;
  }
  :deep(&.c-bitts-empty-state-large-border) {
    @apply bg-white border-none;
  }
}
.partner-population-overrides__enable {
  @apply flex items-center font-bold h-full;
}
.partner-population-overrides__field-selection {
  @apply border border-solid border-neutral-border rounded-bts-md p-16 flex flex-col w-1/2;
}
:deep(.partner-population-overrides__empty-state-icon) {
  @apply text-neutral-accent p-24 rounded-[360px] bg-white bg-top-gradient from-neutral-accent/20 to-neutral-accent/0;
}
.partner-population-overrides__override {
  @apply flex justify-between items-center p-16 font-bold border-2
    bg-neutral-background-weak border-solid border-transparent rounded-bts-md;
  &:hover:not(.partner-population-overrides__selected-override) {
    @apply border-info-accent;
  }
}
.partner-population-overrides__selected-override {
  @apply bg-info-background-weak;
}
.partner-population-overrides__tooltip {
  @apply ml-4 cursor-default border-b border-dashed border-neutral-text-weak;
  :deep(.ant-tooltip-inner) {
    @apply font-normal;
  }
}
</style>
