<template>
  <BittsPopover
    overlay-class="c-list-user-permission-module__container"
    :options="listUsers"
    placement="bottomRight"
    :show-menu="isVisible"
  >
    <template #default>
      <BittsButton
        text="Share"
        size="small"
        type="neutral"
        :active="isVisible"
        @click="emit('invite-button-clicked')"
      />
    </template>
    <template #content>
      <div class="p-16 w-[480px] max-h-[600px]">
        <div class="flex pb-16 text-neutral-text-strong font-bold text-xl">
          <BittsButton
            v-if="!showUsers"
            data-testid="user-perm-back-button"
            :center-icon="['far', 'arrow-left']"
            variant="ghost"
            class="mr-8"
            size="small"
            @click="onClearSelected"
          />
          Invite Team Members
        </div>
        <BittsFormLabel label="Add Members" />
        <BittsMultiselect
          v-model="selectedTeamMemberOptions"
          custom-filter="label"
          placeholder="Type names or emails"
          mode="multiple"
          :options="availableTeamMemberOptions"
          :show-checkboxes="false"
          :show-selected-options="false"
        >
          <template #emptyStateContent>
            <p class="flex justify-center text-neutral-text-weak pb-12">
              {{ emptyStateMessage }}
            </p>
            <BittsButton
              text="Invite Team Members"
              size="x-small"
              class="w-full"
              type="neutral"
              :left-icon="['fas', 'plus']"
              @click="onInviteTeamBtnClicked"
            />
          </template>
        </BittsMultiselect>
        <BittsDivider class="my-16" />
        <div v-if="showUsers" class="overflow-auto max-h-[375px]">
          <p class="text-neutral-text-strong pb-8 font-bold"> List Access </p>
          <ListUserPermissionsCard
            v-for="user in props.listUsers"
            :key="user.user_id"
            :user="user"
            :org="user.org"
            :loading="loadingState[user.user_id]"
            :editable="canAccessBeEdited(user)"
            @option-selected="onClickedRemoveUser"
          />
        </div>
        <div v-else class="flex justify-end">
          <BittsButton
            text="Cancel"
            class="mr-16"
            type="neutral"
            :loading="isLoading"
            @click="onClearSelected"
          />
          <BittsButton
            text="Send Invites"
            :loading="isLoading"
            @click="onClickedSendInvite"
          />
        </div>
      </div>
    </template>
  </BittsPopover>
</template>
<script setup>
import {
  BittsButton,
  BittsDivider,
  BittsFormLabel,
  BittsMultiselect,
  BittsPopover,
} from '@crossbeam/bitts';

import axios from 'axios';
import { sortBy } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import ListUserPermissionsCard from '@/components/shared-list/ListUserPermissionsCard.vue';

import useAuth from '@/composables/useAuth';
import useIteratively from '@/composables/useIteratively';
import { EDITOR, OWNER } from '@/constants/shared_list';
import { captureException } from '@/errors';
import {
  useCollaborateStore,
  useFlashesStore,
  useRolesStore,
  useTeamStore,
} from '@/stores';
import urls from '@/urls';

const props = defineProps({
  listUsers: {
    type: Array,
    default: () => [],
  },
  listId: {
    type: String,
    required: true,
  },
  isVisible: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['invite-button-clicked', 'closed']);
const teamStore = useTeamStore();
const selectedTeamMemberOptions = ref([]);
const isLoading = ref(false);
const loadingState = ref({});

const { currentOrg } = useAuth();
const collaborateStore = useCollaborateStore();
const router = useRouter();
const { iteratively } = useIteratively();

const rolesStore = useRolesStore();
const { rolesById } = storeToRefs(rolesStore);

const flashesStore = useFlashesStore();

const roleIdsWithSharingAccess = computed(() => {
  const roles = [];
  for (const [roleId, role] of Object.entries(rolesById.value)) {
    const roleHasPermission = role.permissions.find(
      (permission) =>
        permission.resource === 'sharing' &&
        permission.scopes.includes('write:sharing'),
    );
    if (roleHasPermission) roles.push(roleId);
  }
  return roles;
});

const availableTeamMemberOptions = computed(() => {
  const unsortedUsers = [];
  teamStore.coreUsers.forEach((coreUser) => {
    const { user, role_id: roleId } = coreUser;
    const userInList = props.listUsers.find(
      (listUser) =>
        listUser.user_id === user.id &&
        listUser.org.organization_id === currentOrg.value.id,
    );
    if (!userInList && roleIdsWithSharingAccess.value.includes(roleId)) {
      unsortedUsers.push({
        ...user,
        value: `${user.id}__${user.first_name}__${user.last_name}__${user.email}`,
        label: `${user.first_name} ${user.last_name}`,
      });
    }
  });
  return sortBy(unsortedUsers, ['label']);
});

function canAccessBeEdited(user) {
  return (
    user.org.organization_id === currentOrg.value.id &&
    user.user_access !== OWNER
  );
}

const showUsers = computed(() => !selectedTeamMemberOptions.value.length);

function onClearSelected() {
  selectedTeamMemberOptions.value = [];
  loadingState.value = {};
}

function formattedIterativelyPayload(user) {
  const userId = user.split('__')[0];
  const userEmail = user.split('__')[3];
  return iteratively.userUpdatedSharedListAccess({
    list_action: 'Added',
    list_id: props.listId,
    access_type: EDITOR,
    impacted_user_id: userId,
    impacted_user_email: userEmail,
  });
}

async function onClickedSendInvite() {
  isLoading.value = true;
  let firstName;
  let lastName;
  const payload = selectedTeamMemberOptions.value.map((teamMember) => {
    const [userId, userFirstName, userLastName] = teamMember.split('__');
    firstName = userFirstName;
    lastName = userLastName;
    return { user_id: Number(userId), user_access: EDITOR };
  });
  try {
    await axios.patch(urls.lists.addPermissions(props.listId), payload);
    await collaborateStore.refreshCollaborateStore();
    selectedTeamMemberOptions.value.forEach((teamMember) =>
      formattedIterativelyPayload(teamMember),
    );
    flashesStore.addSuccessFlash({
      message: `${selectedTeamMemberOptions.value.length === 1 ? `${firstName} ${lastName}` : 'Users'} added to Shared List`,
    });
  } catch (e) {
    captureException(e);
    flashesStore.addErrorFlash({
      message: 'Something went wrong, reach out to our support team for help',
    });
  } finally {
    onClearSelected();
    isLoading.value = false;
  }
}

async function onClickedRemoveUser(option) {
  loadingState.value[option.user.user_id] = true;
  try {
    await axios.delete(
      urls.lists.revokePermissions(props.listId, [{ id: option.user.user_id }]),
    );
    await collaborateStore.refreshCollaborateStore();
    flashesStore.addSuccessFlash({
      message: `${option.user.first_name} ${option.user.last_name} removed from Shared List`,
    });
    iteratively.userUpdatedSharedListAccess({
      list_action: 'Removed',
      list_id: props.listId,
      impacted_user_id: option.user.user_id.toString(),
      impacted_user_email: option.user.email,
    });
  } catch (e) {
    captureException(e);
    flashesStore.addErrorFlash({
      message: 'Something went wrong, reach out to our support team for help',
    });
  } finally {
    loadingState.value[option.user.user_id] = false;
  }
}

const multipleTeamMembersInOrg = computed(
  () =>
    !availableTeamMemberOptions.value.length && teamStore.coreUsers.length > 1,
);

const emptyStateMessage = computed(() => {
  if (multipleTeamMembersInOrg.value) {
    return 'Looks like your whole team has been invited to collaborate';
  }
  return 'To collaborate with more teammates on this Shared List, add more members to your organization';
});

async function onInviteTeamBtnClicked() {
  await router.push({ name: 'invite_user' });
}
</script>
<style lang="pcss" scoped>
.c-list-user-permission-module__container {
  @apply pt-0 z-30;
}
</style>
