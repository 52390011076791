<template>
  <div v-if="showSsoToggle" class="c-select-sso" data-testid="sso-selecting">
    <div class="c-select-sso__left">
      <h3>Pre-Register using SSO</h3>
      <p
        >Users will receive these permissions when logging in via SSO for the
        first time</p
      >
    </div>
    <div class="c-select-sso__right">
      <BittsSwitch
        v-model="isSSO"
        data-testid="invite-sso-switch"
        :disabled="ssoRequired"
      />
    </div>
  </div>
</template>
<script setup>
import { BittsSwitch } from '@crossbeam/bitts';

import { computed } from 'vue';

import useAuth from '@/composables/useAuth';
import { SSO_ONLY, SSO_OPTIONAL } from '@/constants/me';

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const { currentOrg } = useAuth();

const isSSO = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

/* SSO Settings */
const ssoEnabled = computed(
  () => currentOrg.value.login_method === SSO_OPTIONAL,
);
const ssoRequired = computed(() => currentOrg.value.login_method === SSO_ONLY);
const showSsoToggle = computed(() => ssoRequired.value || ssoEnabled.value);
</script>
<style lang="pcss" scoped>
.c-select-sso {
  @apply flex items-center justify-between my-24;
  h3 {
    @apply text-neutral-text-strong font-bold;
  }
  p {
    @apply text-neutral-text text-sm;
  }
}
</style>
