<template>
  <div class="c-dont-worry">
    <FontAwesomeIcon
      :icon="['fad', 'building-shield']"
      class="text-info-accent"
      :style="{ height: '64px' }"
    />
    <h1>Trust is our business</h1>
    <p class="c-dont-worry__summary">
      You have full control over what data we have access to, and you can change
      this at any time.
    </p>
    <DontWorryBox
      title="What we'll do"
      :notes="[
        'Sync only the data you choose',
        'Find matches with your partners',
      ]"
      icon="check"
      color="success"
    />
    <DontWorryBox
      title="What we'll never do"
      :notes="[
        'Manipulate or change your data',
        'Automatically share data with partners',
        'Sell your data to 3rd party services',
      ]"
      icon="check"
      color="danger"
    />
    <DontWorryBox
      title="What you'll do later"
      :notes="[
        'Define your segments (like Customers)',
        'Analyze matches & build tactical reports',
      ]"
      icon="check"
      color="neutral"
    />
    <BittsCheckbox
      v-if="isSalesforce"
      class="c-dont-worry__sandbox"
      data-testid="dont-worry-sandbox"
      label="I am connecting to a sandbox environment"
      @input="handleSandbox"
    />
    <BittsButton
      size="large"
      data-testid="dont-worry-submit"
      :text="submitButtonText"
      class="w-full flex justify-center mt-16"
      @click="handleSubmit"
    />
  </div>
</template>
<script setup>
import { BittsButton, BittsCheckbox } from '@crossbeam/bitts';

import { computed, ref } from 'vue';

import DontWorryBox from '@/components/data-sources/DontWorryBox.vue';

import { SALESFORCE_DATA_SOURCE_TYPE } from '@/constants/data_sources';

const props = defineProps({
  dataSource: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits(['redirect-retrieved']);
const isSalesforce = computed(
  () => props.dataSource.type === SALESFORCE_DATA_SOURCE_TYPE,
);
const submitButtonText = computed(() => {
  return `Connect ${props.dataSource.friendly_name}`;
});

const isSandbox = ref(false);
function handleSandbox() {
  isSandbox.value = !isSandbox.value;
}

function handleSubmit() {
  const payload = {
    integration_type: props.dataSource.type,
    settings_usecase: 'custom',
  };

  if (isSandbox.value) {
    payload.form_properties = { is_sandbox: true };
  }

  emit('redirect-retrieved', payload);
}
</script>
<style lang="pcss" scoped>
.c-dont-worry {
  @apply text-center;
  margin-top: -24px;
}

h1 {
  @apply text-neutral-text-strong font-bold text-xl mb-12 mt-24;
}

.c-dont-worry__summary {
  @apply text-neutral-text text-m mb-16 text-left px-16;
}
</style>
<style lang="pcss">
.c-dont-worry__sandbox {
  @apply py-12;
  .ant-checkbox-wrapper {
    @apply flex justify-center;
  }
}
</style>
