<template>
  <div class="c-list-user-permissions-card__container">
    <BittsAvatarPip
      class="mr-8"
      :show-initials="true"
      :org="props.org"
      :user="props.user"
    />
    <div class="flex flex-col">
      <p class="text-neutral-text-strong font-bold mb-4">
        {{ props.user.first_name }} {{ props.user.last_name }}
      </p>
      <p class="text-neutral-text text-sm">
        {{ props.user.email }}
      </p>
    </div>
    <component
      :is="isDropdownOrText"
      v-bind="bittsDropdownProps"
      class="self-center text-neutral-text mr-18 ml-auto"
      data-testid="user-permission-dropdown"
      @selection="onPermissionSelected"
    >
      <div v-if="!props.loading">
        {{ capitalizedUserPermission }}
      </div>
      <div v-else>
        Removing
        <span class="dot1">.</span>
        <span class="dot2">.</span>
        <span class="dot3">.</span>
      </div>
    </component>
  </div>
</template>
<script setup>
import { BittsAvatarPip, BittsDropdown } from '@crossbeam/bitts';

import { capitalize } from 'lodash';
import { computed } from 'vue';

import { EDITOR, OWNER } from '@/constants/shared_list';

const props = defineProps({
  user: {
    type: Object,
    default: () => {
      // do nothing
    },
  },
  org: {
    type: Object,
    default: () => {
      // do nothing
    },
  },
  editable: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['option-selected']);

const REMOVE_USER = 'remove_user';

const isDropdownOrText = computed(() =>
  props.editable && !props.loading ? BittsDropdown : 'p',
);
const capitalizedUserPermission = computed(() =>
  capitalize(props.user?.user_access || OWNER),
);

const bittsDropdownProps = computed(() => {
  const dropdownprops = {
    prompt: capitalizedUserPermission.value,
    placement: 'bottom',
    options: [
      { value: EDITOR, display: 'Editor', user: props.user, active: true },
      { value: REMOVE_USER, danger: true, display: 'Remove', user: props.user },
    ],
    mountToBody: false,
    parentClass: 'c-list-user-permissions-card__dropdown-container',
  };
  return dropdownprops;
});

function onPermissionSelected(option) {
  if (option.value === REMOVE_USER) {
    emit('option-selected', option);
  }
  // in the future we would want to change someone from an owner to editor or vice versa
  // and can work with that, for now that's not an option so we just want to emit to the parent to remove the user
}
</script>
<style lang="pcss">
.c-list-user-permissions-card__container {
  @apply bg-neutral-background-weak flex p-8 rounded-8 mb-8;

  .c-list-user-permissions-card__dropdown-container {
    @apply pt-0;
  }

  .c-bitts-dropdown .ant-popover-open .c-bitts-dropdown__prompt--basic {
    @apply border-neutral-border-focus;
    border-width: 2px !important;
  }
  .c-bitts-dropdown
    .c-bitts-dropdown__prompt
    .c-bitts-dropdown__prompt--basic
    svg {
    @apply text-neutral-accent;
  }
}
</style>
<style scoped lang="pcss">
.dot1 {
  animation: visibility 3s linear infinite;
}
@keyframes visibility {
  0% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.dot2 {
  animation: visibility2 3s linear infinite;
}
@keyframes visibility2 {
  0% {
    opacity: 0;
  }
  21% {
    opacity: 0;
  }
  22% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.dot3 {
  animation: visibility3 3s linear infinite;
}
@keyframes visibility3 {
  0% {
    opacity: 0;
  }
  43% {
    opacity: 0;
  }
  44% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
</style>
