<template>
  <BittsSelect
    v-model="selectedVisibility"
    dropdown-class-name="population-sharing-visibility-selector"
    :dropdown-match-select-width="false"
    :options="visibilityLevels"
    :searchable="false"
    :use-disabled-options="true"
    class="w-1/2"
    @change="(viz) => emit('sharing-changed', viz)"
  >
    <template #option="{ option }">
      <div class="flex flex-col">
        <div class="text-neutral-text-strong font-bold flex items-center gap-4">
          {{ option.label }}
          <div v-if="option.default" class="text-neutral-text-weak font-normal">
            (default)
          </div>
        </div>
        <div class="text-neutral-text-weak text-sm text-wrap">
          {{ option.description }}
        </div>
      </div>
    </template>
  </BittsSelect>
</template>

<script setup>
import { BittsSelect } from '@crossbeam/bitts';

import { computed, ref, watch } from 'vue';

import {
  HIDDEN,
  OLAPS_ONLY,
  SHARING,
  VISIBILITY_OPTIONS_TEXT,
} from '@/constants/data_shares';

const props = defineProps({
  partnerName: {
    type: String,
    default: 'Partner',
  },
  population: {
    type: Object,
    default: () => {
      // do nothing
    },
  },
  value: {
    type: String,
    default: HIDDEN,
  },
});

const emit = defineEmits(['sharing-changed']);

const VISIBILITY_OPTIONS = [SHARING, OLAPS_ONLY, HIDDEN];

const selectedVisibility = ref(props.value);

watch(
  () => props.value,
  () => {
    selectedVisibility.value = props.value;
  },
);

const visibilityLevels = computed(() => {
  return VISIBILITY_OPTIONS.map((option) => {
    return {
      value: option,
      description: VISIBILITY_OPTIONS_TEXT[option].subtitle({
        partnerName: props.partnerName,
        populationName: props.population.name,
        isTerse: true,
      }),
      label:
        VISIBILITY_OPTIONS_TEXT[option].selectorTitle ||
        VISIBILITY_OPTIONS_TEXT[option].title,
      disabled:
        !props.population.sharingSetting.visibility?.includes(SHARING) &&
        option === SHARING,
      default: props.population.sharingSetting.visibility?.includes(option),
    };
  });
});
</script>

<style lang="pcss" scoped>
.c-dropdown-select__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 8px;
  color: var(--navy);
  font-size: 12px;
  cursor: pointer;
  &:hover {
    background-color: var(--cyan-15);
  }
}

.c-dropdown-select__item--single-select {
  justify-content: flex-start;
}

.c-population-sharing-visibility-option {
  @apply text-base text-neutral-800 mb-16 mx-8;
}
.c-population-sharing-visibility-option {
  @apply text-base text-neutral-800 mb-16 mx-8;
}
</style>

<style lang="pcss">
.population-sharing-visibility-selector {
  @apply w-[257px] overflow-x-hidden;
  .ant-select-item {
    @apply w-[255px] flex items-center;
  }
}
</style>
