<template>
  <div>
    <BittsPopover
      v-if="showEditColumnInput || props.isCustomTextColumnFactory"
      :mount-to-body="true"
      :show-menu="props.showMenu"
      :align="align"
      overlay-class="c-shared-list-column-menu-item__popover"
    >
      <BittsButton
        v-if="props.isCustomTextColumnFactory"
        variant="outline"
        size="x-small"
        :center-icon="['fas', 'add']"
        @click="onMenuButtonClicked"
      />
      <div v-else>
        <FontAwesomeIcon
          class="cursor-pointer"
          :icon="['far', 'bars-filter']"
          :style="{ height: '12px', width: '12px' }"
          @click="onMenuButtonClicked"
        />
      </div>
      <template #content>
        <BittsLoading :is-loading="false">
          <div @mouseleave="onMouseLeave">
            <BittsInput
              v-model="columnName"
              class="mb-8"
              form-label="Column Name"
              placeholder="Add a column name"
              name="column_name"
              :status="hasErrorStatus"
              danger-text="Name must be 30 chars or less"
              @keyup.enter="
                () => {
                  if (!isBtnDisabled) onClickedBtn();
                }
              "
            />
            <BittsButton
              :text="menuButtonText"
              size="x-small"
              type="neutral"
              class="w-full"
              :disabled="isBtnDisabled"
              @click="onClickedBtn"
            />
          </div>
        </BittsLoading>
      </template>
    </BittsPopover>
    <BittsDropdown
      v-else
      :align="align"
      :options="dropdownOptions"
      :mount-to-body="true"
      placement="bottomRight"
      @selection="handleSelection"
    >
      <template #prompt>
        <FontAwesomeIcon
          class="cursor-pointer"
          :icon="['far', 'bars-filter']"
          :style="{ height: '12px', width: '12px' }"
        />
      </template>
    </BittsDropdown>
  </div>
</template>
<script setup>
import {
  BittsButton,
  BittsDropdown,
  BittsInput,
  BittsLoading,
  BittsPopover,
} from '@crossbeam/bitts';

import axios from 'axios';
import { computed, ref } from 'vue';

import useIteratively from '@/composables/useIteratively';
import { EVENT_SITES } from '@/constants/analytics';
import { captureException } from '@/errors';
import { useFlashesStore } from '@/stores';
import urls from '@/urls';

const props = defineProps({
  isCustomTextColumnFactory: {
    type: Boolean,
    default: true,
  },
  params: {
    type: Object,
    default: () => {
      // do nothing
    },
  },
  showMenu: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits([
  'menu-button-clicked',
  'edit-button-clicked',
  'delete-button-clicked',
]);

const MAX_COLUMN_NAME_COUNT = 30;

const flashesStore = useFlashesStore();
const columnName = ref(props.params?.displayName);
const showEditColumnInput = ref(false);

const listId = computed(() => props.params.currentList.id);
const colId = computed(() => props.params.column.colId);

const { iteratively } = useIteratively();

const menuButtonText = computed(() =>
  props.isCustomTextColumnFactory ? 'Create' : 'Save',
);
const hasErrorStatus = computed(() =>
  columnName.value && columnName.value.length > MAX_COLUMN_NAME_COUNT
    ? 'danger'
    : null,
);
const isBtnDisabled = computed(
  () =>
    !!hasErrorStatus.value ||
    !columnName.value ||
    columnName.value === props.params.displayName,
);

const onClickedBtn = computed(() =>
  props.isCustomTextColumnFactory ? onClickedCreateBtn : onClickedSaveBtn,
);

const align = {
  points: ['tr', 'tr'],
  offset: [12, 25],
};

function onMenuButtonClicked(columnName = false) {
  emits('menu-button-clicked', columnName);
}

function onMouseLeave() {
  onMenuButtonClicked();
  columnName.value = props.params?.displayName;
  showEditColumnInput.value = false;
}

async function onClickedCreateBtn() {
  try {
    await axios.post(urls.lists.createColumn(listId.value), {
      display_name: columnName.value,
    });
    submitItlyEvent('Create Column', columnName.value);
    onMenuButtonClicked();
    showEditColumnInput.value = false;
    await props.params.context.resetGrid();
  } catch (e) {
    captureException(e);
    flashesStore.addErrorFlash({
      message: 'Something went wrong, reach out to our support team for help',
    });
  }
}

async function onClickedSaveBtn() {
  try {
    await axios.patch(urls.lists.columns(listId.value, colId.value), {
      display_name: columnName.value,
    });
    submitItlyEvent('Edit Column', columnName.value, props.params.displayName);
    onMenuButtonClicked(columnName.value);
    showEditColumnInput.value = false;
    await props.params.context.refreshStore();
  } catch (e) {
    captureException(e);
    flashesStore.addErrorFlash({
      message: 'Something went wrong, reach out to our support team for help',
    });
  }
}

function submitItlyEvent(action, columnName, oldValue = null) {
  const payload = {
    column_action: action,
    column_name: columnName,
    event_site: EVENT_SITES.SHARED_LIST_COLUMN_MENU,
  };
  if (oldValue) payload.old_value = oldValue;
  iteratively.userActsOnListColumns(payload);
}

const dropdownOptions = [
  { display: 'Edit Column', value: 'edit', iconType: ['far', 'pencil'] },
  { display: 'Delete Column', value: 'delete', iconType: ['far', 'trash'] },
];

function handleSelection({ value }) {
  switch (value) {
    case 'edit':
      showEditColumnInput.value = true;
      onMenuButtonClicked();
      break;
    case 'delete':
      emits('delete-button-clicked');
      break;
  }
}
</script>
<style lang="pcss">
.c-shared-list-column-menu-item__popover {
  .ant-popover-content .ant-popover-inner .ant-popover-inner-content {
    @apply p-12;
  }
}
</style>
