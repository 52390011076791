import axios from 'axios';
import { has, isNull, reduce } from 'lodash';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';

import useAuth from '@/composables/useAuth';
import {
  POSSIBLE_ALERT_STATES,
  POTENTIAL_REVENUE,
  TOTAL_OVERLAPS,
} from '@/constants/amm_grid';
import { MD_DATA_SOURCE_TYPE } from '@/constants/data_sources';
import {
  MDM_PROPERTIES,
  MDM_TYPES,
  MS_DYNAMICS_IS_CLOSED_COLUMN,
} from '@/constants/mdm';
import { COMBINATORS, OPERATORS } from '@/constants/populations';
import {
  ALL_PARTNERS_TYPE,
  GREENFIELD_TYPE,
  MULTI_PARTNER_REPORT_TYPES,
  OVERLAPS,
  PARTNER_GREENFIELD,
  PARTNER_TAGS_TYPE,
  PIPELINE,
  POTENTIAL_REVENUE_TYPE,
  SINGLE_OVERLAPS_TYPE,
} from '@/constants/reports';
import {
  useBillingStore,
  useFeedsStore,
  usePartnersStore,
  usePopulationsStore,
  usePotentialRevenueStore,
  useReportsStore,
} from '@/stores';
import urls from '@/urls';
import { encodeReportInfoForUrl, uuid4 } from '@/utils';

export default function usePartnerOverlapData() {
  const MAX_OVERLAPS_TO_SHOW = 2000;

  const gridInfoLookup = ref({});
  const topOverlaps = ref([]);

  const { currentOrg } = useAuth();

  const reportsStore = useReportsStore();
  const potRevStore = usePotentialRevenueStore();
  const {
    potentialRevenueSettingsSourceField,
    potentialRevenueSettingsSource,
  } = storeToRefs(potRevStore);

  const partnersStore = usePartnersStore();
  const populationsStore = usePopulationsStore();

  const { overlapCounts } = storeToRefs(partnersStore);
  const { populationIds } = storeToRefs(populationsStore);
  const { getFeedById } = useFeedsStore();

  const billingStore = useBillingStore();

  function getPopSourceByMdmType(mdmType, popSources) {
    return popSources.find((source) => source.mdm_type === mdmType) || {};
  }

  function getDealClosedSourceField(dealSource) {
    if (!dealSource) return {};
    const feed = getFeedById(dealSource.feed_id);
    let isClosedField;
    // handle ms dynamics not having mdm property for deal is closed field
    if (feed?.integration?.type === MD_DATA_SOURCE_TYPE) {
      isClosedField = dealSource.fields?.find(
        ({ column }) => column === MS_DYNAMICS_IS_CLOSED_COLUMN,
      );
    } else {
      isClosedField = dealSource.fields?.find(
        ({ mdm_property }) => mdm_property === MDM_PROPERTIES.deal.IS_CLOSED,
      );
    }
    return isClosedField ?? {};
  }

  function sourcesAndFieldsForReports(popSources) {
    const dealSource = getPopSourceByMdmType(MDM_TYPES.DEAL, popSources);
    const userSource = getPopSourceByMdmType(MDM_TYPES.USER, popSources);
    const dealStageSourceField =
      dealSource?.fields?.find(
        ({ mdm_property: property }) => property === MDM_PROPERTIES.deal.STAGE,
      ) ?? {};
    const amountSourceField =
      dealSource?.fields?.find(
        ({ mdm_property: property }) => property === MDM_PROPERTIES.deal.AMOUNT,
      ) ?? {};
    const accountExecutiveSourceField =
      userSource?.fields?.find(
        ({ mdm_property: property }) => property === MDM_PROPERTIES.user.NAME,
      ) ?? {};
    const dealClosedSourceField = getDealClosedSourceField(dealSource);
    return {
      dealSource,
      userSource,
      dealStageSourceField,
      amountSourceField,
      accountExecutiveSourceField,
      dealClosedSourceField,
    };
  }

  function hasUsableOppsData(popSources) {
    const dealSource = getPopSourceByMdmType(MDM_TYPES.DEAL, popSources);
    const { id } = potentialRevenueSettingsSource.value;
    return id && dealSource.id && id === dealSource.id;
  }

  function getPipelineMappingFilters(
    dealSource,
    amountSourceFieldId,
    dealClosedSourceFieldId,
  ) {
    const closedLabel = uuid4();
    const amountLabel = uuid4();
    const andCombinator = COMBINATORS.find(({ label }) => label === 'and').id;

    const closedOperator = OPERATORS.find(({ label }) => label === 'is').id;
    const amountOperator = OPERATORS.find(
      ({ label }) => label === 'greater than',
    ).id;

    let closedOperand;
    const feed = getFeedById(dealSource.feed_id);
    // ms dynamics doesn't have a boolean closed field, checking the deal closed source field statecode instead
    // https://learn.microsoft.com/en-us/dynamics365/customerengagement/on-premises/developer/entities/opportunity?view=op-9-1#BKMK_StateCode
    if (feed.integration.type === MD_DATA_SOURCE_TYPE) {
      closedOperand = ['Open'];
    } else {
      closedOperand = [false];
    }

    const filters = [
      {
        filter_expression: [closedLabel, andCombinator, amountLabel],
        filter_parts: [
          {
            label: closedLabel,
            operand: closedOperand,
            operator: closedOperator,
            source_field_id: dealClosedSourceFieldId,
          },
          {
            label: amountLabel,
            operand: '0',
            operator: amountOperator,
            source_field_id: amountSourceFieldId,
          },
        ],
        is_partner_filter: false,
        organization_id: currentOrg.value.id,
        source_id: dealSource.id,
        tableName: dealSource.table,
      },
    ];
    return encodeReportInfoForUrl(filters);
  }

  function getPipelineMappingColumns(
    dealSource,
    dealStageSourceFieldId,
    amountSourceFieldId,
  ) {
    const columns = [];
    const startColumn = 7;
    if (dealStageSourceFieldId) {
      columns.push({
        column_type: 'source_field',
        organization_id: currentOrg.value.id,
        source_field_id: dealStageSourceFieldId,
        source_id: dealSource.id,
        column_order: startColumn,
        compositeColumnId: `${dealStageSourceFieldId}__source_field`,
        mdm_type: dealSource.mdm_type,
      });
    }
    columns.push({
      column_type: 'source_field',
      organization_id: currentOrg.value.id,
      source_field_id: amountSourceFieldId,
      source_id: dealSource.id,
      column_order: columns.length + startColumn,
      compositeColumnId: `${amountSourceFieldId}__sum_aggregate`,
      mdm_type: dealSource.mdm_type,
    });
    return encodeReportInfoForUrl(columns);
  }

  function getPartnerOrgAndPopulationFromId(id) {
    const partnerPopulation = populationsStore.getPartnerPopulationById(id);
    const partnerOrg = partnersStore.getPartnerOrgById(
      partnerPopulation?.organization_id,
    );
    return { partnerPopulation, partnerOrg };
  }

  function generateDefaultReportName(
    reportType,
    { ourPopulationId, partnerPopulationIds = [], partnerTag, comparisonType },
  ) {
    let population, partnerOrg, partnerPopulation;
    if (!MULTI_PARTNER_REPORT_TYPES.includes(reportType)) {
      ({ partnerOrg, partnerPopulation } = getPartnerOrgAndPopulationFromId(
        parseInt(partnerPopulationIds[0]),
      ));
      population = populationsStore.getPopulationById(ourPopulationId);
    }
    let name = '';
    switch (reportType) {
      case SINGLE_OVERLAPS_TYPE:
        name = `Your ${population?.name || 'population'} vs. ${partnerOrg?.name || 'Partner'}'s ${partnerPopulation?.name || 'population'}`;
        break;
      case POTENTIAL_REVENUE_TYPE:
        name = `Your ${population?.name || 'population'} vs. ${partnerOrg?.name || 'Partner'}'s ${partnerPopulation?.name || 'population'} Pipeline Mapping`;
        break;
      case GREENFIELD_TYPE:
        if (comparisonType === PARTNER_GREENFIELD)
          name = `${partnerOrg?.name || 'Partner'}'s Greenfield Accounts vs. You`;
        else
          name = `Your Greenfield Accounts vs. ${partnerOrg?.name || 'Partner'}`;
        break;
      case PARTNER_TAGS_TYPE:
        name = `Your Populations vs. ${partnerTag?.label} Tag`;
        break;
      case ALL_PARTNERS_TYPE:
        name = 'Your Populations vs. All Partners';
        break;
      default: /* custom type */ {
        const MAX_PARTNER_NAMES = 3;
        const partnerOrgNames = partnerPopulationIds.reduce((names, popId) => {
          const name = getPartnerOrgAndPopulationFromId(parseInt(popId))
            .partnerOrg?.name;
          if (!names.includes(name)) names.push(name);
          return names;
        }, []);
        const namesForString = partnerOrgNames.slice(0, MAX_PARTNER_NAMES);
        name = `Your Populations vs. ${[...namesForString].join(', ')}`;
        if (partnerOrgNames.length > MAX_PARTNER_NAMES) name += ' and others';
      }
    }
    const reportsIncludingName = reportsStore.getReportsIncludingString(name);
    if (!reportsIncludingName.length) return name;
    return reportsIncludingName.reduce((reportName, report) => {
      if (report.name === name) return reportName; // if it doesn't have a number at the end, we don't care about it
      const currentNum = parseInt(reportName.slice(-2));
      let reportNameNum = parseInt(report.name.slice(-2));
      if (currentNum <= reportNameNum) return `${name} (${++reportNameNum})`;
      return reportName;
    }, `${name} (1)`);
  }

  const GRID_TYPE_TO_REPORT_TYPE_MAP = {
    [POTENTIAL_REVENUE]: POTENTIAL_REVENUE_TYPE,
    [TOTAL_OVERLAPS]: SINGLE_OVERLAPS_TYPE,
  };

  function reportURL(
    { populationId, partnerPopulationId, gridType, popSources },
    useDefaultName = false,
  ) {
    const REPORT_VIEW_ROUTE_NAME = 'view_report';
    let reportSearchParams = {
      our_population_ids: populationId.toString(),
      partner_population_ids: partnerPopulationId.toString(),
    };
    if (useDefaultName) {
      const reportType = GRID_TYPE_TO_REPORT_TYPE_MAP[gridType];
      reportSearchParams.name = generateDefaultReportName(reportType, {
        ourPopulationId: populationId,
        partnerPopulationIds: [partnerPopulationId],
      });
    }
    if (gridType === POTENTIAL_REVENUE) {
      const {
        dealSource,
        dealStageSourceField,
        amountSourceField,
        dealClosedSourceField,
      } = sourcesAndFieldsForReports(popSources);

      const dealStageSourceFieldId = dealStageSourceField.id;
      const dealClosedSourceFieldId = dealClosedSourceField.id;
      let amountSourceFieldId = amountSourceField.id;

      // if deal source in report is same as pot inf deal source use pot inf amount
      if (hasUsableOppsData(popSources)) {
        amountSourceFieldId = potentialRevenueSettingsSourceField.value.id;
      }

      // has required deal info for default columns and filters
      if (
        !has(dealSource, 'id') ||
        !has(dealSource, 'table') ||
        !dealClosedSourceFieldId ||
        !amountSourceFieldId
      ) {
        return { name: REPORT_VIEW_ROUTE_NAME, query: reportSearchParams };
      }

      reportSearchParams = {
        ...reportSearchParams,
        columns: getPipelineMappingColumns(
          dealSource,
          dealStageSourceFieldId,
          amountSourceFieldId,
        ),
        filters: getPipelineMappingFilters(
          dealSource,
          amountSourceFieldId,
          dealClosedSourceFieldId,
        ),
      };
      reportSearchParams.consolidated_report_type = PIPELINE;
    } else reportSearchParams.consolidated_report_type = OVERLAPS;
    return { name: REPORT_VIEW_ROUTE_NAME, query: reportSearchParams };
  }

  const dismissedAlertStates = ref([]);
  function onDismissAlert(alert, partner) {
    const alertAndOrgId = `${alert}__${currentOrg.value.id}__${partner.id}`;
    window.localStorage?.setItem(alertAndOrgId, 'dismissed');
    this.dismissedAlertStates = [...dismissedAlertStates.value, alert];
  }
  function setDismissedAlertsFromLocalStorage(partner) {
    const initialDismissedAlerts = [];
    POSSIBLE_ALERT_STATES.forEach((state) => {
      const alertAndOrgId = `${state}__${currentOrg.value.id}__${partner.id}`;
      const localAlertInfo = window.localStorage?.getItem(alertAndOrgId);
      if (localAlertInfo && localAlertInfo === 'dismissed') {
        initialDismissedAlerts.push(state);
      }
    });
    dismissedAlertStates.value = initialDismissedAlerts;
  }

  function partnerOverlapUsage(id) {
    if (!overlapCounts.value.overlap_usage) return null;
    return overlapCounts.value.overlap_usage.by_partner.find(
      (usage) => usage.partner_organization_id === id,
    );
  }
  function partnerPotentialRevenueNumber(id) {
    return (
      partnerOverlapUsage(id) && partnerOverlapUsage(id).open_deals_total_amount
    );
  }
  function canSeeOppsData(id, showFreeTierEmptyState = true) {
    if (billingStore.isFreeTier) return !showFreeTierEmptyState;
    return partnerPotentialRevenueNumber(id) !== null;
  }

  function enrichOverlaps(overlaps, partnerPopulations) {
    return overlaps.reduce((acc, overlap) => {
      const population = populationsStore.getPopulationById(
        overlap.our_population_id,
      );
      if (!population) return acc;

      const popSize = population.meta.total_size;
      const popOrgMap = reduce(
        partnerPopulations,
        (acc, pop) => {
          acc[pop.id] = pop.organization_id;
          return acc;
        },
        {},
      );
      let greenfield = popSize - overlap.num_matches;
      if (isNaN(greenfield)) greenfield = popSize || null;
      acc.push({
        ...overlap,
        primary_population_id: overlap.our_population_id,
        partner_organization_id: popOrgMap[overlap.partner_population_id],
        population_total: popSize,
        overlap_count: overlap.num_matches,
        overlap_percent: popSize ? (overlap.num_matches / popSize) * 100 : 0,
        greenfield,
      });
      return acc;
    }, []);
  }

  async function getGridInfoForPartner(partnerId) {
    await populationsStore.readySync;
    const partnerPops = populationsStore.getPartnerPopulationsByOrg(partnerId);
    const noPartnerPopulations = !partnerPops.length;
    if (noPartnerPopulations) {
      gridInfoLookup.value[partnerId] = [];
      return { partnerInfo: {}, overlaps: [] };
    }
    const params = {
      overlap_only: false,
      limit: MAX_OVERLAPS_TO_SHOW,
      partner_org_ids: [partnerId],
    };
    const ownPops = populationIds.value;
    if (ownPops.length) params.our_population_ids = ownPops;
    const url = urls.overlaps.byPartner(partnerId);
    const { data } = await axios.post(url, params);
    const overlaps = enrichOverlaps(data.items, partnerPops).filter(
      (olap) => !isNull(olap.num_matches),
    );
    const partnerInfo = data.partner_info;
    gridInfoLookup.value[partnerId] = { partnerInfo, overlaps };
    return { partnerInfo, overlaps };
  }

  async function loadTopOverlaps(limit = 5) {
    const params = { overlap_only: true, limit };
    if (populationIds.value.length)
      params.our_population_ids = populationIds.value;
    const response = await axios.post(urls.overlaps.v0_3, params);
    topOverlaps.value = response.data.items;
  }

  return {
    canSeeOppsData,
    dismissedAlertStates,
    enrichOverlaps,
    generateDefaultReportName,
    getGridInfoForPartner,
    gridInfoLookup,
    hasUsableOppsData,
    loadTopOverlaps,
    onDismissAlert,
    partnerOverlapUsage,
    partnerPotentialRevenueNumber,
    reportURL,
    setDismissedAlertsFromLocalStorage,
    topOverlaps,
  };
}
