<template>
  <BittsModal
    title="Delete rows?"
    confirm-type="danger"
    save-text="Delete"
    :saving="isSaving"
    :width="400"
    :visible="true"
    @saved="onDeleteConfirmed"
    @closed="onModalClosed"
  >
    <template #content>
      <p class="text-neutral-text">
        Are you sure you want to delete these rows? Your collaborators will no
        longer be able to see these opportunities, and this action can not be
        undone.
      </p>
    </template>
  </BittsModal>
</template>
<script setup>
import { BittsModal } from '@crossbeam/bitts';

import axios from 'axios';
import { computed, ref } from 'vue';

import useIteratively from '@/composables/useIteratively';
import { EVENT_SITES } from '@/constants/analytics';
import { captureException } from '@/errors';
import { useCollaborateStore, useFlashesStore } from '@/stores';
import urls from '@/urls';

const props = defineProps({
  rowsSelected: {
    type: Array,
    required: true,
  },
  listId: {
    type: String,
    required: true,
  },
  currentNumRecords: {
    type: Number,
    required: true,
  },
});
const emit = defineEmits(['saved', 'closed']);
const collaborateStore = useCollaborateStore();
const flashesStore = useFlashesStore();
const { iteratively } = useIteratively();

const isSaving = ref(false);

const newNumRecordsInList = computed(
  () => props.currentNumRecords - props.rowsSelected.length,
);

function onModalClosed() {
  emit('closed');
}

async function onDeleteConfirmed() {
  try {
    isSaving.value = true;
    await axios.post(urls.lists.deleteRows(props.listId), {
      row_ids: props.rowsSelected.map((row) => row.rowId),
    });
    const singleOrMultipleRows =
      props.rowsSelected.length > 1 ? 'rows have' : 'row has';
    flashesStore.addSuccessFlash({
      message: `Your ${singleOrMultipleRows} successfully been deleted`,
    });
    iteratively.userUpdatedListRecords({
      event_site: EVENT_SITES.LIST_DELETE_ROWS_MODAL,
      previous_value: props.currentNumRecords.toString(),
      new_value: newNumRecordsInList.value.toString(),
      action: 'removed records',
      action_location: 'Collaborate',
    });
    emit('saved');
    collaborateStore.refreshCollaborateStore();
  } catch (error) {
    const message =
      error.response?.data?.errors?.join(',') ||
      'Something went wrong, reach out to our support team for help';
    captureException(error);
    flashesStore.addErrorFlash({ message });
    onModalClosed();
  } finally {
    isSaving.value = false;
  }
}
</script>
