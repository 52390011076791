<template>
  <div class="c-sharing-dashboard-populations__container">
    <BittsCarousel
      unique-key="id"
      :items
      :custom-title="true"
      :dismissable="false"
      btn-size="small"
      :width="340"
      :use-full-width="!popsWithMetadata.length"
    >
      <template #title>
        <h4 class="font-bold text-lg text-neutral-text">
          Your Population Default Settings
        </h4>
      </template>
      <template #carousel-card="{ item: pop }">
        <SharingDashboardPopCard
          :key="pop.id"
          :pop
          :has-manage-pops
          :default-partners="pop?.partnersUsingDefaults"
        />
      </template>
    </BittsCarousel>
  </div>
</template>
<script setup>
import { BittsCarousel } from '@crossbeam/bitts';

import { sortBy } from 'lodash';
import { computed } from 'vue';

import SharingDashboardPopCard from '@/components/partners/SharingSettingsDashboard/SharingDashboardPopCard.vue';

import { STANDARD_TYPE } from '@/constants/sharing_dashboard';

const props = defineProps({
  popsWithMetadata: {
    type: Object,
    required: true,
  },
  hasManagePops: {
    type: Boolean,
    default: false,
  },
});
const OPEN_OPPS = 'open_opportunities';
const CUSTOMERS = 'customers';
const PROSPECTS = 'prospects';

const emptyStandardPops = [
  {
    id: 'customers',
    standard_type: CUSTOMERS,
    name: '+ Customers',
    description: 'This should be a list of your current customers',
    isEmptyStateCard: true,
  },
  {
    id: 'open opportunities',
    standard_type: OPEN_OPPS,
    name: '+ Open Opportunities',
    description: 'All the common open opportunities we are sharing',
    isEmptyStateCard: true,
  },
  {
    id: 'prospects',
    standard_type: PROSPECTS,
    name: '+ Prospects',
    description: 'All the common prospects we are sharing',
    isEmptyStateCard: true,
  },
];

const items = computed(() => {
  const standardPops = props.popsWithMetadata.map((pop) => pop.standard_type);
  const emptyPopsWithoutIncluded = emptyStandardPops.filter(
    (emptyPop) => !standardPops.includes(emptyPop.standard_type),
  );
  return sortBy(
    [...emptyPopsWithoutIncluded, ...props.popsWithMetadata],
    [STANDARD_TYPE],
  );
});
</script>
<style lang="pcss">
.c-sharing-dashboard-populations__container {
  @apply mb-60;
  .c-bitts-carousel {
    .c-bitts-carousel__header {
      @apply flex items-center mb-16 justify-between;
    }
  }
}
</style>
