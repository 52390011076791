<template>
  <div class="relative w-full flex">
    <UpsellOverlay
      v-if="listDisabled"
      text="Share accounts with partners and close more opportunities "
      title="Collaborate in real-time"
      class="rounded-16 border border-neutral-border-weak border-solid"
      :billing-interaction="{
        cta: BILLING_CTAS.SHARED_LISTS,
        event_site: EVENT_SITES.SHARED_LIST_TILE_OVERLAY,
      }"
    />
    <button
      class="c-tile"
      :class="{ 'cursor-not-allowed': listDisabled }"
      @click="goToList"
      type="button"
    >
      <BittsAvatarStack
        class="c-tile__avatar-stack"
        size="medium"
        :org-list="list.permissions"
      />
      <div class="c-tile__company-name">
        {{ list.name }}
      </div>
      <div class="c-tile__description">
        {{ list.description }}
      </div>
      <div class="c-tile__users">
        <BittsAvatarItem
          v-for="{ user, org } in usersAndOrgs"
          :key="user.user_id"
          class="c-tile__user-container"
          size="small"
          spacing="condensed"
          :contains-pip="true"
          :description="`${user.email}`"
          :is-user-main-avatar="true"
          :org="org"
          :show-initials="true"
          :title="`${user.first_name} ${user.last_name}`"
          :user="user"
        />
      </div>
      <div class="text-sm text-neutral-text-weak mt-24">
        {{ `${recordNumber} records • Last updated ${lastUpdated}` }}
      </div>
    </button>
  </div>
</template>
<script setup>
import { BittsAvatarItem, BittsAvatarStack } from '@crossbeam/bitts';

import { DateTime } from 'luxon';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import UpsellOverlay from '@/components/billing/UpsellOverlay.vue';

import { BILLING_CTAS, EVENT_SITES } from '@/constants/analytics';
import { useCollaborateStore } from '@/stores';

const props = defineProps({
  list: {
    type: Object,
    default: () => {
      // do nothing
    },
  },
});

const collaborateStore = useCollaborateStore();
const listCreator = computed(() =>
  collaborateStore.getOwnerByListId(props.list.id),
);
const listPartner = computed(() =>
  collaborateStore.getPartnerOwnerByListId(props.list.id),
);
const ownerOrg = computed(() =>
  collaborateStore.getOwnerOrgByListId(props.list.id),
);
const partnerOwnerOrg = computed(() =>
  collaborateStore.getPartnerOrgByListId(props.list.id),
);

const recordNumber = computed(() => props.list?.row_count);
const lastUpdated = computed(() =>
  DateTime.fromISO(props.list?.updated_at).toRelative(),
);

const listDisabled = computed(() => !props.list['is_enabled?']);

const usersAndOrgs = computed(() => [
  { user: listCreator.value, org: ownerOrg.value },
  { user: listPartner.value, org: partnerOwnerOrg.value },
]);

const router = useRouter();

async function goToList() {
  if (listDisabled.value) return;
  await router.push({
    name: 'collaborate_list',
    params: { id: props.list.id },
  });
}
</script>

<style lang="pcss">
.c-tile {
  @apply flex flex-col items-start rounded-2xl border border-neutral-border bg-white p-16 min-h-[250px] w-full shadow-component;

  .c-tile__avatar-stack {
    @apply mb-12;
  }

  .c-tile__company-name {
    @apply text-neutral-text-strong text-m font-bold text-left;
    overflow: hidden !important;
    max-width: 270px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .c-tile__description {
    @apply text-sm text-neutral-text overflow-hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .c-tile__user-container {
    @apply mt-16;
    .bitts-avatar-item__text {
      @apply max-w-[80%] text-left;
    }
    .bitts-avatar-item__text > .bitts-avatar-item__text-secondary,
    .bitts-avatar-item__text-title {
      @apply truncate inline-block overflow-hidden;
    }
  }
  .c-tile__users {
    @apply grid grid-cols-2 mt-auto gap-8;
    width: inherit;
  }
}

.all-lists {
  .upsell-overlay {
    @apply rounded-b-bts-lg h-[85%] self-end;
    .c-bitts-empty-state__content {
      @apply text-sm;
    }
    .c-bitts-empty-state-large {
      @apply pb-0;
    }
    .c-bitts-empty-state-large
      .c-bitts-empty-state__content
      .c-bitts-empty-state-title {
      @apply text-base pb-2;
    }
    .c-bitts-empty-state-large .c-bitts-empty-state__content {
      @apply mt-12;
    }
    .fa-wand-magic-sparkles {
      height: 20px !important;
    }
  }
}
</style>
