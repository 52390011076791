<template>
  <div class="c-individual-list-page__results-table">
    <AgGridVue
      class="ag-theme-alpine w-full h-full"
      dom-layout="autoHeight"
      row-selection="multiple"
      row-class="fullstory-hide dd-privacy-mask"
      :column-defs
      :context
      :default-col-def="defaultColSetup"
      :default-col-group-def="defaultGroupHeaderSetup"
      :header-height="40"
      :group-header-height="32"
      :modules
      :row-data
      :get-row-id="(params) => params.data.row_id"
      :suppress-browser-resize-observer="true"
      :suppress-column-virtualisation="true"
      :suppress-row-hover-highlight="true"
      :suppress-drag-leave-hides-columns="true"
      :suppress-row-click-selection="true"
      :suppress-cell-focus="true"
      @row-selected="onRowSelected"
      @grid-ready="onGridReady"
    />
  </div>
</template>
<script setup>
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { AgGridVue } from '@ag-grid-community/vue3';
import { ref } from 'vue';

import CustomColumnHeader from '@/components/shared-list/CustomColumnHeader.vue';
import CustomColumnHeaderGroup from '@/components/shared-list/CustomColumnHeaderGroup.vue';
import SharedListIntegrationCellRenderer from '@/components/shared-list/SharedListIntegrationCellRenderer.vue';
import SharedListNotesRenderer from '@/components/shared-list/SharedListNotesRenderer.vue';
import SharedListOpportunitiesEditorCell from '@/components/shared-list/SharedListOpportunitiesEditorCell.vue';
import SharedListOpportunitiesRendererCell from '@/components/shared-list/SharedListOpportunitiesRendererCell.vue';
import SharedListRecordCell from '@/components/shared-list/SharedListRecordCell.vue';
import SharedListUserCell from '@/components/shared-list/SharedListUserCell.vue';

import useSharedList from '@/composables/useSharedList';

defineProps({
  columnDefs: {
    type: Array,
    default: () => [],
  },
  rowData: {
    type: Array,
    default: () => [],
  },
  context: {
    type: Object,
    default: () => {
      void 0;
    },
  },
});

const emits = defineEmits(['open-modal', 'grid-ready', 'update-rows']);

const { currentList } = useSharedList();

const modules = ref([ClientSideRowModelModule]);

function onGridReady(params) {
  emits('grid-ready', params.api);
}

const defaultColSetup = {
  headerComponent: 'CustomColumnHeader',
  headerComponentParams: {
    currentList: currentList.value,
    isDefaultColType: true,
  },
  lockPinned: true,
  minWidth: 200,
  sortable: true,
  sortingOrder: ['asc', 'desc'],
};

const defaultGroupHeaderSetup = {
  headerGroupComponent: 'CustomColumnHeaderGroup',
};

function onRowSelected(e) {
  const selected = e.node.isSelected();
  const rowId = e.node.data.row_id;
  if (selected) {
    const {
      name,
      website,
      record_id: recordId,
      source_id: sourceId,
      is_greenfield: isGreenfield,
    } = e.node.data;
    const rowInfo = {
      ...e.node.data,
      name,
      website,
      recordId,
      sourceId,
      listId: currentList.value.id,
      rowId,
      isGreenfield,
    };
    emits('update-rows', rowInfo);
  } else {
    emits('update-rows', rowId);
  }
}
</script>
<script>
/* eslint-disable vue/no-unused-components */
export default {
  components: {
    AgGridVue,
    CustomColumnHeader,
    CustomColumnHeaderGroup,
    SharedListIntegrationCellRenderer,
    SharedListNotesRenderer,
    SharedListOpportunitiesEditorCell,
    SharedListOpportunitiesRendererCell,
    SharedListRecordCell,
    SharedListUserCell,
  },
};
</script>
<style lang="pcss">
.c-individual-list-page__results-table {
  .ag-row {
    @apply border-l-0 border-r-0 border-t-0 bg-white border-neutral-border;
  }

  .ag-cell,
  .ag-header-cell {
    @apply border-0 border-r border-b border-solid border-neutral-background-disabled;
  }

  .ag-cell-inline-editing {
    @apply bg-white shadow-none rounded-none;
    border-color: theme(colors.neutral.border) !important;
  }

  .ag-header {
    @apply border-b;
  }
  .ag-root-wrapper {
    border: none !important;
  }
  .ag-row-odd {
    @apply bg-white;
  }
  .ag-header-cell.ag-focus-managed.ag-header-active:not(
      .ag-header-cell-sortable
    ) {
    &:hover {
      @apply bg-white;
    }
  }
  .ag-header-cell.ag-header-cell-sortable.ag-focus-managed {
    &:hover {
      @apply bg-white;
    }
  }

  .ag-row {
    border-bottom: none;
    &.ag-row-selected::before {
      @apply bg-white;
    }
  }
  .ag-header-cell-text {
    @apply text-neutral-text-strong font-bold;
  }
  .ag-header-row-column-group {
    @apply flex items-center px-12 py-8 gap-12 bg-neutral-background border-t-0;
  }
  .ag-header-group-cell-partner,
  .ag-header-group-cell-own,
  .ag-header-group-private {
    @apply border-solid border-r border-neutral-border;
  }
  .ag-header-group-cell-partner {
    @apply border-t-2 border-t-accent-accent;
  }
  .ag-header-group-cell-own {
    @apply border-t-2 border-t-info-accent;
  }
  .ag-header-group-private {
    @apply border-t-2 border-t-primary-border  bg-neutral-background-disabled;
  }
  .ag-cell-private,
  .ag-header-cell.ag-column-group-private {
    @apply bg-neutral-background;
  }

  .ag-center-cols-container {
    width: 100%;
  }
  .ag-theme-alpine .ag-header-row:not(:first-child) .ag-header-cell {
    @apply border-t-0 border-b-0;
  }
  .ag-header-cell {
    @apply border-r border-solid border-neutral-border bg-white px-12 py-8;
  }
  .ag-header-group-cell {
    @apply px-12 py-8;
  }

  .ag-cell {
    @apply flex items-center px-12 py-8  border-neutral-border;
  }
  .ag-cell-wrap-text {
    word-break: normal !important;
  }
  .ag-cell:not(.ag-cell-wrap-text) {
    .ag-cell-wrapper {
      @apply truncate;
    }
  }
  .ag-cell-edit-wrapper__error {
    .ag-cell-wrapper
      > .ag-cell-edit-wrapper
      > .ag-cell-editor
      > .ag-wrapper
      > .ag-input-field-input {
      &:focus {
        @apply border-2 border-solid border-danger-border-focus rounded-8;
      }
    }
  }

  .ag-header-group-private.ag-header-group-cell:not(
      .ag-column-resizing
    ).ag-header-group-cell:not(.ag-column-hover):not(
      .ag-header-cell-moving
    ):hover {
    @apply bg-neutral-background-disabled;
  }

  .ag-column-group-private.ag-header-cell:not(
      .ag-column-resizing
    ).ag-header-cell:not(.ag-column-hover):not(.ag-header-cell-moving):hover {
    @apply bg-neutral-background;
  }

  .ag-theme-alpine .ag-checkbox-input-wrapper,
  .ag-theme-alpine .ag-checkbox-input {
    @apply cursor-pointer;
  }

  .ag-theme-alpine .ag-checkbox-input-wrapper:focus-within,
  .ag-theme-alpine .ag-checkbox-input-wrapper:active {
    box-shadow: none;
  }

  .ag-theme-alpine :not(.ag-checked).ag-checkbox-input-wrapper {
    @apply border border-solid border-neutral-border;
  }

  .ag-theme-alpine :not(.ag-checked).ag-checkbox-input-wrapper:hover {
    @apply border-2 border-solid border-info-accent cursor-pointer;
  }
  .ag-theme-alpine .ag-checkbox-input-wrapper:hover {
    @apply cursor-pointer;
  }

  .ag-theme-alpine .ag-checkbox-input-wrapper.ag-indeterminate::after {
    @apply bg-info-accent mt-3 ml-3;
    content: '';
    height: 8px;
    width: 8px;
  }

  .ag-theme-alpine .ag-checkbox-input-wrapper:hover.ag-indeterminate::after {
    @apply mt-2 ml-2;
  }

  .ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
    @apply text-info-accent rounded-3;
  }

  .ag-theme-alpine :not(.ag-checked).ag-checkbox-input-wrapper::after {
    content: '';
  }

  .ag-theme-alpine .ag-cell-private.ag-cell-inline-editing {
    @apply rounded-4 border-2 border-solid bg-white;
    border-color: theme(colors.neutral.border-focus) !important;
  }
  .ag-cell-wrapper {
    @apply w-full;
  }
  .ag-ltr .ag-header-select-all {
    @apply mr-0;
  }
}
</style>
