<template>
  <div
    v-if="
      coreRemovals.length ||
      salesRemovals.length ||
      downgradedUsers.length ||
      revokedInvites.length
    "
    class="seat-removal-summary"
  >
    <h3>{{ title }}</h3>
    <DowngradeGraph
      v-for="auth in coreRemovals"
      :key="`core-removal--${auth.user.id}`"
      :data-testid="`core-removal--${auth.user.id}`"
    >
      <template #left-item>
        <BittsAvatar
          :user="auth?.user"
          :show-initials="true"
          class="mr-6"
          size="x-small"
        />
      </template>
      <p
        ><strong>{{ getName(auth) }}</strong> will be removed & lose access to
        Crossbeam</p
      >
    </DowngradeGraph>
    <DowngradeGraph
      v-for="auth in salesRemovals"
      :key="`sales-removal--${auth.user.id}`"
      :data-testid="`sales-removal--${auth.user.id}`"
    >
      <template #left-item>
        <BittsAvatar
          :user="auth?.user"
          :show-initials="true"
          class="mr-6"
          size="x-small"
        />
      </template>
      <p
        ><strong>{{ getName(auth) }}</strong> will be removed & lose access to
        Crossbeam for Sales</p
      >
    </DowngradeGraph>
    <DowngradeGraph
      v-for="auth in downgradedUsers"
      :key="`sales-downgrade--${auth.user.id}`"
      :data-testid="`sales-downgrade--${auth.user.id}`"
    >
      <template #left-item>
        <BittsAvatar
          :user="auth?.user"
          :show-initials="true"
          class="mr-6"
          size="x-small"
        />
      </template>
      <p
        ><strong>{{ getName(auth) }}</strong> will lose access to Crossbeam for
        Sales</p
      >
    </DowngradeGraph>
    <DowngradeGraph
      v-if="revokedInvites.length"
      data-testid="invite-removal"
      class="ml-[-2px]"
    >
      <template #left-item>
        <div
          class="h-16 w-16 ml-2 flex items-center justify-center rounded-full bg-neutral-200 mr-6"
        >
          <FontAwesomeIcon
            :icon="['fas', 'envelope']"
            class="w-8 h-8 text-neutral-accent"
          />
        </div>
      </template>
      <p class="mr-48">
        {{ revokedInvites.length === 1 ? 'An invitation' : 'Invitations' }} to
        <span v-html="formatList(revokedInvites, { bold: true })" /> will be
        revoked
      </p>
    </DowngradeGraph>
  </div>
</template>
<script setup>
import { BittsAvatar } from '@crossbeam/bitts';

import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import DowngradeGraph from '@/components/billing/DowngradeGraph.vue';

import { ADMIN_PANEL } from '@/constants/team';
import { useTeamStore } from '@/stores';
import { formatList } from '@/utils';

const props = defineProps({
  userIds: {
    type: Set,
    default: () => new Set([]),
  },
  inviteIds: {
    type: Set,
    default: () => new Set([]),
  },
  isCancellation: {
    type: Boolean,
    default: true,
  },
  title: {
    type: String,
    default: "Here's how your plan changes",
  },
});
const teamStore = useTeamStore();
const { salesOnlyUsers, authorizations } = storeToRefs(teamStore);

/* Users who have a core role and were not kept */
const coreRemovals = computed(() =>
  authorizations.value
    .filter((auth) => !props.userIds.has(auth.user.id))
    .filter((auth) => auth.role_id)
    .filter((auth) => auth.authorizer_type !== ADMIN_PANEL),
);

/* Sales only users who were not kept */
const salesRemovals = computed(() =>
  salesOnlyUsers.value.filter((auth) => !props.userIds.has(auth.user.id)),
);

/* Invites that will be revoked */
const revokedInvites = computed(() =>
  teamStore.redeemableInvites
    .filter((invite) => !props.inviteIds.has(invite.id))
    .map((invite) => invite.email),
);

/* Users who were kept but have a sales role, and who will have their sales role removed */
const downgradedUsers = computed(() =>
  props.isCancellation
    ? authorizations.value
        .filter((auth) => props.userIds.has(auth.user.id))
        .filter((auth) => auth.sales_edge_role)
        .filter((auth) => auth.authorizer_type !== ADMIN_PANEL)
    : [],
);

/* Helper functions 🤝 */
function getName(auth) {
  if (auth.user.first_name && auth.user.last_name)
    return `${auth.user.first_name} ${auth.user.last_name}`;
  return auth.user.email;
}
</script>
<style lang="pcss" scoped>
h3 {
  @apply text-neutral-text-strong text-m font-bold mb-16;
}
</style>
