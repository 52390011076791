<template>
  <div class="overlaps-table-overlaps-cell">
    <div class="overlaps-table-overlaps-cell__overlaps">
      {{ overlapNumber }}
    </div>
    <button
      class="overlaps-table-overlaps-cell__link-to-sharing"
      @click="viewSharing"
      type="button"
    >
      View Sharing
    </button>
  </div>
</template>

<script>
import Humanize from 'humanize-plus';

export default {
  name: 'OverlapsTableOverlapsCell',
  components: {},
  data() {
    return { overlaps: 0, id: 0 };
  },
  computed: {
    overlapNumber() {
      return this.overlaps ? Humanize.intComma(this.overlaps) : '--';
    },
  },
  beforeMount() {
    this.overlaps = this.params.data.overlaps || 0;
    this.id = this.params.data.org.id;
  },
  methods: {
    async viewSharing() {
      await this.$router.push({
        name: 'partner_details',
        params: { partner_org_id: this.id },
        query: { tab: 'sharing' },
      });
    },
  },
};
</script>

<style lang="pcss" scoped>
.overlaps-table-overlaps-cell {
  @apply h-full w-full flex items-center justify-between;
}
.overlaps-table-overlaps-cell__link-to-sharing {
  @apply text-sm text-neutral-text-button font-bold;
}
.overlaps-table-overlaps-cell__overlaps {
  @apply text-neutral-text text-m;
}
</style>
