<template>
  <div class="c-seat-alerts">
    <BittsAlert
      v-if="isDowngradingSelf && !isRemovingLastAdmin"
      color="warning"
      data-testid="downgrade-alert"
      message="You are downgrading your current role"
      description="If you make this change you won’t be able to change it again"
    />
    <BittsAlert
      v-else-if="isRemovingLastAdmin"
      color="error"
      message="You can't complete this action"
      data-testid="last-admin-alert"
      description="You need at least 1 admin user, this action would remove all admins"
    />
  </div>
</template>

<script setup>
import { BittsAlert } from '@crossbeam/bitts';

import { storeToRefs } from 'pinia';
import { computed, watch } from 'vue';

import useAuth from '@/composables/useAuth';
import { useRolesStore, useTeamStore } from '@/stores';

const props = defineProps({
  userIds: {
    type: Array,
    default: () => [],
  },
  pickedCoreRole: {
    type: String,
    default: null,
  },
});
const emit = defineEmits(['is-removing-last-admin']);
const teamStore = useTeamStore();
const rolesStore = useRolesStore();
const { authorizations } = storeToRefs(teamStore);
const { allRoles } = storeToRefs(rolesStore);
const { currentUser, isAdmin } = useAuth();

const adminRoleId = computed(
  () => allRoles.value.find((role) => role.name === 'Admin')?.id,
);
const hasAllAdminsSelected = computed(() => {
  return authorizations.value
    .filter((role) => role.role_id === adminRoleId.value && role.user)
    .every(({ user }) => props.userIds.includes(user.id));
});

const changingToNonAdminCore = computed(
  () => props.pickedCoreRole !== adminRoleId.value,
);
const isRemovingLastAdmin = computed(
  () => hasAllAdminsSelected.value && changingToNonAdminCore.value,
);
const includesSelf = computed(() =>
  props.userIds.includes(parseInt(currentUser.value.id)),
);
const isDowngradingSelf = computed(
  () => isAdmin.value && changingToNonAdminCore.value && includesSelf.value,
);

watch(isRemovingLastAdmin, (bool) => {
  emit('is-removing-last-admin', bool);
});

emit('is-removing-last-admin', isRemovingLastAdmin.value);
</script>

<style lang="pcss" scoped>
.c-seat-alerts {
  @apply w-full;
}
</style>
