<template>
  <div class="c-sharing-dashboard-pop-detail__container">
    <div class="flex justify-between">
      <p class="w-1/6 mr-8 c-sharing-dashboard-pop-detail__title">
        {{ isOwn ? 'Your Populations' : `${partner.name}'s Populations` }}
      </p>
      <p class="w-4/6 c-sharing-dashboard-pop-detail__title"> Sharing </p>
      <p class="w-1/6 c-sharing-dashboard-pop-detail__title">
        {{ isOwn ? 'Using Custom' : 'Action' }}
      </p>
    </div>
  </div>
  <div
    v-for="pop in popsToDisplay"
    :key="pop.id"
    class="c-sharing-dashboard-pop-detail__content-container"
  >
    <div class="flex justify-between mx-24 py-12 items-center">
      <div class="w-1/6 mr-8">
        <div class="relative z-10 bg-white max-w-content">
          <div class="flex items-center">
            <SharingDashboardInlinePop
              :population="pop"
              :class="{ 'mb-12': !!trackedPop2PopSettings[pop.id]?.length }"
              :svg="isOwn ? getSourceIcon(pop) : null"
            />
            <BittsTooltip
              v-if="!isOwn && isPartnerSharingGreenfield(pop)"
              overlay-class="c-sharing-dashboard-pop-detail__greenfield-tooltip"
            >
              <FontAwesomeIcon
                :icon="['fas', 'leaf']"
                :style="{
                  height: '12px',
                  width: '12px',
                  color: 'currentColor',
                }"
                class="text-success-accent ml-8"
                :class="{ 'mb-12': !!trackedPop2PopSettings[pop.id]?.length }"
              />
              <template #title>
                This Partner is sharing Greenfield accounts
              </template>
            </BittsTooltip>
          </div>
        </div>
        <div
          v-for="setting in trackedPop2PopSettings[pop.id]"
          :key="`pop_to_pop_${setting.dataShare.id}`"
          class="flex justify-center items-center max-h-[32px] bg-white px-8 mb-4"
        >
          <BittsSvg
            svg="armLine"
            class="c-sharing-dashboard-pop-detail__line"
          />
          <p class="text-neutral-text text-sm"> Population override </p>
        </div>
      </div>
      <div class="w-4/6 c-sharing-dashboard-pop-detail__title">
        <div v-if="pop?.isEmptyDetail" class="flex items-center">
          <div v-if="isOwn" class="flex items-center">
            <p class="text-neutral-text-weak text-sm font-normal">
              Your organization doesn’t have this population yet
            </p>
            <BittsButton
              text="Create Population"
              size="x-small"
              variant="ghost"
              :disabled="!hasManagePops"
              class="ml-4"
              @click="emits('create-pop-clicked', pop)"
            />
          </div>
          <p v-else class="text-neutral-text-weak text-sm font-normal">
            This partner doesn’t have this population or isn’t sharing with you.
            Reach out to them directly to fix this
          </p>
        </div>
        <div v-else>
          <SharingSetting
            v-if="!!Object.values(trackedDataShares).length || !isOwn"
            class="flex items-center"
            :class="{ 'mb-12': !!pop?.populationToPopulationSettings?.length }"
            :data-share="
              isOwn
                ? trackedDataShares[pop.id]
                : { ...pop?.dataShare, sourceFields: pop?.sourceFields }
            "
            :is-own="isOwn"
          />
          <PopulationToPopulationSharingSetting
            v-for="setting in trackedPop2PopSettings[pop.id]"
            :key="`pop_to_pop_${setting?.dataShare?.id}`"
            class="mb-4"
            :setting="setting"
            :has-population-refresh="true"
            :partner-name="partner.name"
            :direction="isOwn ? 'outgoing' : 'incoming'"
          />
        </div>
      </div>
      <div
        class="w-1/6 c-sharing-dashboard-pop-detail__title flex flex-col self-start"
      >
        <div v-if="isOwn && !pop?.isEmptyDetail">
          <BittsTag
            :text="!isUsingDefault(pop) ? 'Yes' : 'No'"
            :color="!isUsingDefault(pop) ? 'accent' : 'neutral'"
            variant="rounded"
            :class="{ 'mb-12': !!trackedPop2PopSettings[pop.id]?.length }"
            size="small"
          />
          <div
            v-if="trackedPop2PopSettings[pop.id]?.length && hasManageSharing"
          >
            <BittsButton
              v-for="setting in trackedPop2PopSettings[pop.id]"
              :key="`pop_to_pop_${setting.dataShare.id}`"
              text="Delete"
              size="x-small"
              variant="ghost"
              class="py-4 mb-4"
              type="danger"
              @click="onDeleteOverride(setting.dataShare.id)"
            />
          </div>
        </div>
        <ShareRequestActions
          v-else-if="
            !pop.isEmptyDetail &&
            isPartnerPopAndHasSharing &&
            pop?.requestDataIsVisible
          "
          :data-request-information="trackedDataRequest[pop.id]"
          @share-request-action-event="
            (response) => shareRequestActionEvent(response, pop)
          "
          @data-requested="onRequestData(pop)"
        />
      </div>
    </div>
    <IncomingShareRequestCard
      v-if="isOwn && !!incomingShareRequests[pop.id]"
      :incoming-share-request="incomingShareRequests[pop.id]"
      @clicked-edit-sharing="onEditSharing(pop)"
      @ignored-data-request="onIgnoredDataRequest(pop)"
    />
  </div>
  <DeleteOverrideModal
    :delete-override-modal-visible
    :data-share-id
    @modal-closed="onDeleteOverrideModalClosed"
  />
  <DataShareRequestModal
    :data-share-request-modal-visible
    :partner-pops
    :org-ids="[props.partner.id]"
    @modal-closed="onDataShareRequestModalClosed"
  />
  <SharingSettingsModal
    v-if="popForSharing"
    :partner-id="partner.id"
    :population="popForSharing"
    :visible="sharingModalVisible"
    @hide-sharing-modal="onHideSharingModal"
  />
</template>
<script setup>
import {
  BittsButton,
  BittsSvg,
  BittsTag,
  BittsTooltip,
} from '@crossbeam/bitts';

import { differenceWith } from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref } from 'vue';

import DataShareRequestModal from '@/components/partners/DataShareRequestModal.vue';
import SharingSettingsModal from '@/components/partners/DataSharingSettings/SharingSettingsModal.vue';
import IncomingShareRequestCard from '@/components/partners/IncomingShareRequestCard.vue';
import PopulationToPopulationSharingSetting from '@/components/partners/PopulationToPopulationSharingSetting.vue';
import ShareRequestActions from '@/components/partners/ShareRequestActions.vue';
import DeleteOverrideModal from '@/components/partners/SharingSettingsDashboard/DeleteOverrideModal.vue';
import SharingDashboardInlinePop from '@/components/partners/SharingSettingsDashboard/SharingDashboardInlinePop.vue';
import SharingSetting from '@/components/populations/SharingSetting.vue';

import useAuth from '@/composables/useAuth';
import {
  useDataRequestsStore,
  useDataSharesStore,
  useFeedsStore,
} from '@/stores';

const props = defineProps({
  popsToDisplay: {
    type: Array,
    default: () => [],
  },
  pops: {
    type: Array,
    default: () => [],
  },
  isOwn: {
    type: Boolean,
    default: true,
  },
  partner: {
    type: Object,
    default: () => {
      // do nothing
    },
  },
  hasManageSharing: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['create-pop-clicked', 'request-data-clicked']);

const { feeds } = storeToRefs(useFeedsStore());

const dataRequestsStore = useDataRequestsStore();
const dataSharesStore = useDataSharesStore();

const { hasPermission } = useAuth();
const hasManagePops = computed(() => hasPermission('manage:populations'));

const trackedPop2PopSettings = ref({});
const trackedDataRequest = ref({});

function isPartnerSharingGreenfield(pop) {
  return dataSharesStore.greenfieldSharesLookup[props.partner.id]?.includes(
    pop.id,
  );
}

function getSourceIcon(pop) {
  if (pop?.base_schema) {
    const feed = feeds.value.find(
      (feed) => feed.schema_name === pop.base_schema,
    );
    if (feed) {
      return `${feed.integration.type}Icon`;
    }
  }
  return '';
}

function isUsingDefault(pop) {
  return pop.partnersUsingDefaults.filter(
    (partner) => props.partner.id === partner.id,
  ).length;
}

function shareRequestActionEvent(response, pop) {
  trackedDataRequest.value[pop.id] = response;
}

const isPartnerPopAndHasSharing = computed(
  () => !props.isOwn && props.hasManageSharing,
);

const deleteOverrideModalVisible = ref(false);
const dataShareId = ref(null);

function onDeleteOverride(datashareId) {
  deleteOverrideModalVisible.value = true;
  dataShareId.value = datashareId;
}

function onDeleteOverrideModalClosed(response) {
  deleteOverrideModalVisible.value = false;
  if (response) {
    deleteTrackedOverride();
  }
  dataShareId.value = null;
}

function deleteTrackedOverride() {
  if (props.isOwn) {
    for (const pop in trackedPop2PopSettings.value) {
      if (trackedPop2PopSettings.value[pop]) {
        const currentPopOverrides = trackedPop2PopSettings.value[pop];
        trackedPop2PopSettings.value[pop] = currentPopOverrides.filter(
          (override) => override?.dataShare?.id !== dataShareId.value,
        );
      }
    }
  }
}

const dataShareRequestModalVisible = ref(false);
const partnerPops = ref(null);

function onRequestData(partnerPop) {
  dataShareRequestModalVisible.value = true;
  partnerPops.value = { [props.partner.id]: [partnerPop] };
}

function onDataShareRequestModalClosed(response) {
  dataShareRequestModalVisible.value = false;
  if (response) {
    if (!props.isOwn) {
      trackedDataRequest.value[response.partner_population_id] = response;
    }
  }
}

const incomingShareRequests = ref({});
function getIncomingShareRequest(pop) {
  const request = dataRequestsStore.getInboundShareRequestByPartnerAndPop(
    props.partner?.id,
    pop.id,
  );
  return request?.status === 'pending' ? request : false;
}

const sharingModalVisible = ref(false);
const trackedDataShares = ref({});
async function onHideSharingModal() {
  await dataSharesStore.refreshDataSharesStore();
  const updatedDataShare = dataSharesStore.getOutgoingSharingRules({
    populationId: popForSharing.value.id,
    partnerOrgId: props.partner.id,
  })[0];
  const sharingHasChanged =
    !!differenceWith(
      trackedDataShares.value[popForSharing.value.id],
      updatedDataShare,
    ).length ||
    trackedDataShares.value[popForSharing.value.id].visibility !==
      updatedDataShare.visibility;
  if (sharingHasChanged) {
    trackedDataShares.value[popForSharing.value.id] = updatedDataShare;
    onIgnoredDataRequest(popForSharing.value);
  }
  sharingModalVisible.value = false;
  popForSharing.value = null;
}

const popForSharing = ref(null);
function onEditSharing(pop) {
  sharingModalVisible.value = true;
  popForSharing.value = pop;
}

function onIgnoredDataRequest(pop) {
  incomingShareRequests.value[pop.id] = false;
}

onMounted(async () => {
  await dataRequestsStore.refreshDataRequestsStore();
  props.pops.forEach((pop) => {
    if (!props.isOwn) {
      trackedDataRequest.value[pop.id] = pop.dataRequestInformation;
    }
    if (props.isOwn) {
      incomingShareRequests.value[pop.id] = getIncomingShareRequest(pop);
      trackedDataShares.value[pop.id] = pop.dataShare;
    }
    trackedPop2PopSettings.value[pop.id] = pop.populationToPopulationSettings;
  });
});
</script>
<style scoped lang="pcss">
.c-sharing-dashboard-pop-detail__container {
  @apply px-24 py-8 border-b border-neutral-border border-solid;
  .c-sharing-dashboard-pop-detail__title {
    @apply text-neutral-text-weak text-sm font-bold;
  }
}
.c-sharing-dashboard-pop-detail__line {
  @apply relative bottom-20 mr-4;
}

.c-sharing-dashboard-partner-card__detail-container {
  .c-sharing-dashboard-pop-detail__content-container:not(:last-of-type) {
    @apply border-b border-solid border-neutral-border;
  }
}
</style>
<style lang="pcss">
.c-sharing-dashboard-pop-detail__greenfield-tooltip div {
  @apply flex items-start;
}
.c-sharing-dashboard-pop-detail__content-container {
  .c-incoming-share-request-card {
    @apply mb-12 mx-24 mt-8;
  }
}
</style>
